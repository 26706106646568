export const fetchAllocations = () => ({
  type: FETCH_ALLOCATIONS_TYPE,
});

export const updateAllocations = (allocations) => {
  return {
    type: UPDATE_ALLOCATIONS_TYPE,
    payload: { allocations },
  };
};

export const distributeAllocationsEvenly = (allocations) => ({
  type: DISTRIBUTE_ALLOCATIONS_EVENLY_TYPE,
  payload: { allocations },
});

export const removeAllocation = (allocationCardIndex) => ({
  type: REMOVE_ALLOCATION_TYPE,
  payload: {
    allocationCardIndex,
  },
});

export const removeBackup = (backup) => ({
  type: REMOVE_BACKUP_TYPE,
  payload: {
    backup,
  },
});

export const removePersonBackupOnProgress = (backup) => ({
  type: REMOVE_PERSON_BACKUP_ON_PROGRESS_TYPE,
  payload: {
    backup,
  },
});

export const updateCharityBackupOnProgress = ({
  backup,
  charityId,
  isCustomInput,
}) => {
  return {
    type: UPDATE_CHARITY_BACKUP_ON_PROGRESS_TYPE,
    payload: {
      backup,
      charityId,
      isCustomInput,
    },
  };
};

export const removeCharityBackupOnProgress = (backup) => ({
  type: REMOVE_CHARITY_BACKUP_ON_PROGRESS_TYPE,
  payload: {
    backup,
  },
});

export const backupsNextPage = () => ({
  type: BACKUPS_NEXT_PAGE_TYPE,
});

export const personBackupUpdatedSuccesfully = (backup) => ({
  type: UPDATE_BACKUP_SUCCESSFULLY_TYPE,
  payload: { backup },
});

export const personBackupRemovedSuccesfully = (backup) => ({
  type: REMOVE_BACKUP_SUCCESSFULLY_TYPE,
  payload: { backup },
});

export const charityBackupUpdatedSuccesfully = (charityName) => ({
  type: UPDATE_CHARITY_BACKUP_SUCCESSFULLY_TYPE,
  payload: { charityName },
});

export const charityBackupRemovedSuccesfully = (charityName) => ({
  type: REMOVE_CHARITY_BACKUP_SUCCESSFULLY_TYPE,
  payload: { charityName },
});

export const updatePrimaryBeneficiary = () => ({
  type: UPDATE_PRIMARY_BENEFICIARY_TYPE,
});

export const updatePredecease = () => ({
  type: UPDATE_PREDECEASE_TYPE,
});

export const updateInheritance = () => ({
  type: UPDATE_INHERITANCE_TYPE,
});

export const fetchBackup = () => ({
  type: FETCH_BACKUP_TYPE,
});
export const updateBackup = () => ({
  type: UPDATE_BACKUP_TYPE,
});

export const addAgeMilestone = () => ({
  type: ADD_INHERITANCE_AGE_MILESTONE_TYPE,
});

export const removeAgeMilestone = (milestoneIndex) => ({
  type: REMOVE_INHERITANCE_AGE_MILESTONE_TYPE,
  payload: { milestoneIndex },
});

export const updateAgeMilestoneVisibility = (milestonesVisibility) => ({
  type: TOGGLE_INHERITANCE_AGE_MILESTONE_VISIBILITY_TYPE,
  payload: { milestonesVisibility },
});

export const fetchInheritance = () => ({
  type: FETCH_INHERITANCE_TYPE,
});

export const updateAllocationCard = (allocationCardIndex) => {
  return {
    type: UPDATE_ALLOCATION_CARD_TYPE,
    payload: { allocationCardIndex },
  };
};
export const updateLastAllocationsValidState = (allocations) => ({
  type: UPDATE_LAST_ALLOCATIONS_VALID_STATE_TYPE,
  payload: { allocations },
});

export const forcePieChartAnimation = (count) => ({
  type: FORCE_PIE_CHART_ANIMATION_TYPE,
  payload: { count },
});

export const updatePieChartData = (form, field, isRemoving) => {
  return {
    type: UPDATE_PIE_CHART_DATA_TYPE,
    meta: { form, field, isRemoving },
  };
};

export const openModalCustomCharity = () => ({
  type: OPEN_MODAL_CUSTOM_CHARITY_TYPE,
});

export const FETCH_ALLOCATIONS_TYPE = "FETCH_ALLOCATIONS";
export const UPDATE_ALLOCATIONS_TYPE = "UPDATE_ALLOCATIONS";
export const DISTRIBUTE_ALLOCATIONS_EVENLY_TYPE =
  "DISTRIBUTE_ALLOCATIONS_EVENLY";
export const REMOVE_ALLOCATION_TYPE = "REMOVE_ALLOCATION";
export const REMOVE_BACKUP_TYPE = "REMOVE_BACKUP_TYPE";

export const UPDATE_PRIMARY_BENEFICIARY_TYPE = "UPDATE_PRIMARY_BENEFICIARY";
export const UPDATE_PREDECEASE_TYPE = "UPDATE_PREDECEASE";
export const UPDATE_INHERITANCE_TYPE = "UPDATE_INHERITANCE";
export const FETCH_BACKUP_TYPE = "FETCH_BACKUP";
export const UPDATE_BACKUP_TYPE = "UPDATE_BACKUP";
export const ADD_INHERITANCE_AGE_MILESTONE_TYPE =
  "ADD_INHERITANCE_AGE_MILESTONE";
export const REMOVE_INHERITANCE_AGE_MILESTONE_TYPE =
  "REMOVE_INHERITANCE_AGE_MILESTONE";
export const TOGGLE_INHERITANCE_AGE_MILESTONE_VISIBILITY_TYPE =
  "TOGGLE_INHERITANCE_AGE_MILESTONE_VISIBILITY";
export const FETCH_INHERITANCE_TYPE = "FETCH_INHERITANCE";
export const UPDATE_ALLOCATION_CARD_TYPE = "UPDATE_ALLOCATION_CARD";
export const UPDATE_LAST_ALLOCATIONS_VALID_STATE_TYPE =
  "UPDATE_LAST_ALLOCATIONS_VALID_STATE";
export const FORCE_PIE_CHART_ANIMATION_TYPE = "FORCE_PIE_CHART_ANIMATION";
export const UPDATE_PIE_CHART_DATA_TYPE = "UPDATE_PIE_CHART_DATA";
export const UPDATE_BACKUP_SUCCESSFULLY_TYPE = "UPDATE_BACKUP_SUCCESSFULLY";
export const REMOVE_BACKUP_SUCCESSFULLY_TYPE = "REMOVE_BACKUP_SUCCESSFULLY";
export const BACKUPS_NEXT_PAGE_TYPE = "BACKUPS_NEXT_PAGE";
export const REMOVE_PERSON_BACKUP_ON_PROGRESS_TYPE =
  "REMOVE_BACKUP_ON_PROGRESS";
export const UPDATE_CHARITY_BACKUP_ON_PROGRESS_TYPE =
  "UPDATE_CHARITY_BACKUP_ON_PROGRESS";
export const REMOVE_CHARITY_BACKUP_ON_PROGRESS_TYPE =
  "REMOVE_CHARITY_BACKUP_ON_PROGRESS";
export const UPDATE_CHARITY_BACKUP_SUCCESSFULLY_TYPE =
  "UPDATE_CHARITY_BACKUP_SUCCESSFULLY";
export const REMOVE_CHARITY_BACKUP_SUCCESSFULLY_TYPE =
  "REMOVE_CHARITY_BACKUP_SUCCESSFULLY";
export const OPEN_MODAL_CUSTOM_CHARITY_TYPE = "OPEN_MODAL_CUSTOM_CHARITY";
