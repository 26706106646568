import React from "react";
import Box from "@material-ui/core/Box";
import { Body } from "../../ui/Typography";
import {
  PARTNER_DISCOUNT,
  PROMO_DISCOUNT,
  REFERRAL_DISCOUNT,
} from "../../../constants/plans";
import { TextButton } from "../../ui/Button";

const DiscountsInfo = ({
  translations,
  discountsData,
  isReceiptPage,
  isLoading,
  handleRemoveReferral,
  promoCode,
  hasCaaMembership,
}: PaymentSummaryDiscountInfoProp) => {
  return discountsData.map(({ label, amount, type }: DiscountDataType) => {
    return (
      <Box key={type} display="flex" justifyContent="space-between">
        <Box py={0.25}>
          {type === PARTNER_DISCOUNT && (
            <Body
              component="span"
              bold={false}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
          {type !== PARTNER_DISCOUNT && (
            <Body component="span" bold={false}>
              {label}
              {type === REFERRAL_DISCOUNT && !isReceiptPage && (
                <Box component="span" ml={0.5}>
                  <TextButton
                    text={
                      isLoading
                        ? translations.referralRemovalIsLoading
                        : translations.button.remove
                    }
                    onClick={handleRemoveReferral}
                    isLoading={undefined}
                    loadingLabel={undefined}
                    displayArrowLeft={undefined}
                  />
                </Box>
              )}
              {promoCode && !hasCaaMembership && type === PROMO_DISCOUNT && (
                <Body component="span">{promoCode}</Body>
              )}
            </Body>
          )}
        </Box>
        <Box width={105} textAlign="right" py={0.25}>
          <Body component="span" bold={false}>
            {amount}
          </Body>
        </Box>
      </Box>
    );
  });
};

export default DiscountsInfo;
