import React from "react";
import Box from "@material-ui/core/Box";
import { Body } from "../../ui/Typography";
import DiscountsInfo from "./DiscountInfo";
import PlanDetails from "./PlanDetails";

const PaymentSummaryProductInfoContent = ({
  translations,
  orderValue,
  plansDetails,
  addonsInCart = [],
  hasDiscount,
  discountsData,
  isReceiptPage,
  isLoading,
  handleRemoveReferral,
  promoCode,
  hasCaaMembership,
  isAddon = false,
}: PaymentSummaryProductInfoContentProps) => {
  // mapping through the addon array to handle multiple addons in the future
  if (isAddon) {
    return addonsInCart.map((addon) => {
      const addonProductLabel =
        addon.name === translations.addon_products.digital_execution
          ? translations.addon_products.digital_execution
          : addon.name;
      // const addonQuantity = addon.quantity; Design doesn't have quantity, adding this here for future use
      const addonsPrice = addon.price;
      return (
        <Box
          key={addon.id}
          mt={1}
          display="flex"
          justifyContent="space-between"
        >
          <Box py={0.25}>
            <Body component="span" bold={false}>
              {addonProductLabel}
            </Body>
          </Box>
          <Box py={0.25} display="flex" alignItems="center">
            <Body component="span" bold={false}>
              {addonsPrice}
            </Body>
          </Box>
        </Box>
      );
    });
  }
  return (
    <>
      <Box mt={1} display="flex" justifyContent="space-between">
        <Box py={0.25}>
          <Body component="span" bold={false}>
            {translations.planName}
          </Body>
        </Box>
        <Box py={0.25} display="flex" alignItems="center">
          <Body component="span" bold={false}>
            {orderValue}
          </Body>
        </Box>
      </Box>
      <Box mt={0.5} pl={2}>
        <PlanDetails plansDetails={plansDetails || []} />
      </Box>
      {hasDiscount && (
        <Box tabIndex={0}>
          <DiscountsInfo
            translations={translations}
            discountsData={discountsData}
            isReceiptPage={isReceiptPage}
            isLoading={isLoading}
            handleRemoveReferral={handleRemoveReferral}
            promoCode={promoCode}
            hasCaaMembership={hasCaaMembership}
          />
        </Box>
      )}
    </>
  );
};

export default PaymentSummaryProductInfoContent;
