import React from "react";
import R from "ramda";
import Box from "@material-ui/core/Box";
import { useSelect } from "downshift";
import { DropdownSelectWrapper } from "./style";
import { ArrowBlueUpIcon, ArrowBlueDownIcon } from "../../icons/ArrowIcons";
import { ErrorMessage } from "../../Error";
import { SmallBody, Body } from "../../Typography";
import { isValidSelectKey } from "../../../../selectors/utils/validate";

const onKeyDownHandler = (evt, onChange) => {
  if (onChange && isValidSelectKey(evt.key)) {
    const optionElement = document.querySelector(
      "[aria-selected='true'] > span",
    );
    const optionValue = optionElement
      ? optionElement.getAttribute("value").trim()
      : null;
    if (optionValue !== null) {
      // The API sometimes expects a 'number' and sometimes expects a 'string'
      // here we are making sure the correct value is passed down to 'onChange'
      const isValueNotNumber =
        optionValue !== "" && Number.isNaN(Number(optionValue));
      const parsedValue = isValueNotNumber ? optionValue : Number(optionValue);
      onChange(parsedValue);
    }
  }
};

const SelectInput = ({
  value,
  onChange,
  error,
  touched,
  optionList,
  contentPush = false, // control whether dropdown pushes content or overlaps
  label,
  labelColor = "text.primary",
  placeholder,
  marginBottom = 2,
  className,
  isDisabled,
}) => {
  const initialSelectedItem = R.find(R.propEq("value", value), optionList);
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({ items: optionList, initialSelectedItem });
  const selectedValue = initialSelectedItem || selectedItem;
  const shouldDisplayError = !!error;
  let displayValue = placeholder;
  if (selectedValue) {
    displayValue = selectedValue.node
      ? selectedValue.node
      : selectedValue.label;
  }
  // Adding a '.filter' before '.map' will avoid rendering an invisible tabindex
  // option that can only be seen/accessed while scrolling using the keyboard
  const filteredOptionsList = optionList
    .filter((option) => option !== selectedValue)
    .map((option, index) => (
      <li
        className={`qa-dropdown-item qa-dropdown-item-${option.value}`}
        style={
          highlightedIndex === index
            ? { backgroundColor: "#bde4ff", cursor: "pointer" }
            : { cursor: "pointer" }
        }
        key={`${option}${index}`}
        {...getItemProps({
          item: option,
          index,
          onClick: () => {
            onChange(option.value);
          },
        })}
      >
        <Body component="span" value={option.value}>
          {option.node ? option.node : option.label}
        </Body>
      </li>
    ));

  const toggleButtonProps = getToggleButtonProps({
    disabled: isDisabled,
  });

  return (
    <Box
      onKeyDown={(evt) => onKeyDownHandler(evt, onChange)}
      mb={marginBottom}
      width="100%"
    >
      <DropdownSelectWrapper
        contentPush={contentPush}
        selectedOption={selectedValue}
        isOpen={isOpen}
        className={className}
        error={error}
        data-hj-suppress
      >
        <label htmlFor={label} className="dropdown-label " {...getLabelProps()}>
          <SmallBody color={labelColor} id={label}>
            {label}
          </SmallBody>
        </label>
        <button
          type="button"
          className={`dropdown-toggle qa-dropdown ${
            isDisabled ? "disabled" : ""
          }`}
          {...toggleButtonProps}
        >
          <span className="dropdown-header">
            {displayValue}
            {isOpen ? <ArrowBlueDownIcon /> : <ArrowBlueUpIcon />}
          </span>
        </button>
        <ul {...getMenuProps()} className="dropdown-panel">
          {filteredOptionsList}
        </ul>
        {shouldDisplayError && (
          <Box mt={0.5}>
            <ErrorMessage error={error} />
          </Box>
        )}
      </DropdownSelectWrapper>
    </Box>
  );
};

export default SelectInput;
