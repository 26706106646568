import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { displayModal } from "../../actions/modal";
import { MODAL_REFER_A_FRIEND } from "../../constants/modal";
import { analyticsClickedNavLink } from "../../actions/analytics";
import GiftPlansIcon from "./icons/GiftPlanIcon";
import { selectHeaderTranslations } from "../../selectors/header";
import { colorPalette, media } from "../../config/Theme";
import { selectCurrentRoute } from "../../selectors/location";
import { WELCOME_BACK_PATH } from "../../constants/routes";

const WidgetLabel = styled.span`
  display: inline-block;
  padding: 2px 4px;
  margin-right: 16px;
  border-radius: 4px;
  color: ${colorPalette.willfulYellow};
  font-weight: bold;
  font-size: 16p;
  background: ${colorPalette.willfulBlueDark2};
  position: relative;

  ${media.mobile} {
    font-size: 14px;
  }

  &:before {
    display: block;
    content: "";
    width: 6px;
    height: 6px;
    background: ${colorPalette.willfulBlueDark2};
    position: absolute;
    right: -3px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
`;

const Widget = styled.a`
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

const AnimationContainer = styled.div`
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: 3;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0);
    }
    25% {
      transform: rotate(20deg);
    }
    75% {
      transform: rotate(-20deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const GiftIconComponent = () => {
  const currentRoute = useSelector(selectCurrentRoute);
  const isWelcomeBackPage = currentRoute === WELCOME_BACK_PATH;
  if (isWelcomeBackPage) {
    return (
      <AnimationContainer>
        <GiftPlansIcon color="#ffffff" />
      </AnimationContainer>
    );
  }
  return <GiftPlansIcon color="#ffffff" />;
};

export const EarnAndReferWidget = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();
  const { referFriendWidget } = useSelector(selectHeaderTranslations);
  return (
    <Box display="flex" mr={isDesktop ? 1.5 : 1}>
      <Widget
        onClick={() => {
          dispatch(displayModal(MODAL_REFER_A_FRIEND));
          analyticsClickedNavLink("Account", "Refer a friend");
        }}
      >
        <WidgetLabel>{referFriendWidget}</WidgetLabel>
        <GiftIconComponent />
      </Widget>
    </Box>
  );
};
