import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { selectTranslations } from "../../selectors/translations";
import { MODAL_REFER_A_FRIEND } from "../../constants/modal";
import { Body, ExternalLink, SmallExternalLink } from "../ui/Typography";
import PresentIcon from "../ui/icons/PresentIcon";
import { referralProgramProps } from "../../selectors/referral-program";
import {
  analyticsClickExternalLink,
  analyticsCopiedReferralLink,
  analyticsManuallyCopiedReferralLink,
} from "../../actions/analytics";
import {
  MODAL_COPY_LINK_BUTTON_CLASS,
  MODAL_REFERRAL_LINK_CLASS,
} from "../../constants/growsurf";
import {
  BULK_PURCHASE_GIFTS_URL,
  REFERRAL_PROGRAM_AMAZON_TERMS_AND_CONDITIONS,
  REFERRAL_PROGRAM_WILLFUL_ZENDESK_URL,
} from "../../constants/routes";
import { colorPalette } from "../../config/Theme";
import ModalContext from "../../contexts/ModalContext";
import { featureMobileDrawerEnabled } from "../../utilities/featureFlags";

let hasSetEvent = false;

export const ReferAFriendModal = () => {
  const { setMobileDrawer } = useContext(ModalContext);
  const translations = useSelector(selectTranslations(["global", "notice"]))[
    MODAL_REFER_A_FRIEND
  ];
  const referralProps = useSelector(referralProgramProps);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  const [hasGSLoaded, setHasGSLoaded] = useState(false);

  const loadGS = async () => {
    setHasGSLoaded(true);
    await window.growsurf.init();
    window.removeEventListener("grsfReady", loadGS);
  };

  // Growsurf script is placed on the page by BE (application.haml via _grow_surf.erb)
  // however, that script needs to load first before we initialize the
  // embeddable form elements (referral link code and share buttons)
  // https://docs.growsurf.com/integrate/javascript-web-api/embeddable-elements
  useEffect(() => {
    setMobileDrawer(featureMobileDrawerEnabled());
    if (!window.growsurf || !window.growsurf.init) {
      window.addEventListener("grsfReady", loadGS, []);
    } else {
      setHasGSLoaded(true);
      window.growsurf.init();
    }

    let copyButton = null;
    let referralInput = null;
    const interval = setInterval(() => {
      copyButton = document.querySelector(MODAL_COPY_LINK_BUTTON_CLASS);
      referralInput = document.querySelector(MODAL_REFERRAL_LINK_CLASS);
      if (copyButton && referralInput && !hasSetEvent) {
        copyButton.addEventListener("click", handleCopyButtonClick);
        referralInput.addEventListener("copy", handleManualCopy);
        referralInput.addEventListener("mousedown", handleMouseDown);
        referralInput.addEventListener("keydown", handleKeyDown);
        hasSetEvent = true;
      }
    }, 200);

    let isManuallySelected = false;

    const handleMouseDown = () => {
      isManuallySelected = true;
    };

    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
        isManuallySelected = true;
      }
    };

    const handleCopyButtonClick = () => {
      isManuallySelected = false;
      dispatch(analyticsCopiedReferralLink());
    };

    const handleManualCopy = () => {
      if (isManuallySelected) {
        dispatch(analyticsManuallyCopiedReferralLink());
      }
      isManuallySelected = false;
    };

    // Clean up event listeners on component unmount
    return () => {
      clearInterval(interval);
      if (copyButton) {
        copyButton.removeEventListener("click", handleCopyButtonClick);
      }
      if (referralInput) {
        referralInput.removeEventListener("copy", handleManualCopy);
      }
    };
  }, []);

  if (!hasGSLoaded) return null;

  return (
    <div id="modal--refer-a-friend">
      <style>
        {/* The important styles are here ONLY because we need to do so to override the inline styles attached to the growsurf widget */}
        {`
          div.grsf-copy-link input.grsf-share-url.grsf-form-control {
            border-radius: 1px !important;
            box-shadow: none !important;
            outline: 1px solid ${colorPalette.willfulGrey2};
          }
        `}
      </style>
      <Box mt={1} mb={isTabletDown ? 2 : 1} display={!isTabletDown && "flex"}>
        <Box
          width={isTabletDown ? "100%" : "calc(100% - 100px)"}
          mr={isDesktop && 1}
          mb={isTabletDown && 2}
        >
          <Body>{translations.body}</Body>
        </Box>
        <Box display="flex" height={1} justifyContent="center" ml="auto">
          <PresentIcon width={100} />
        </Box>
      </Box>
      <Box mb={2} textAlign={isTabletDown && "center"}>
        <ExternalLink
          href={BULK_PURCHASE_GIFTS_URL}
          text={translations.giftCardsLink}
          onClick={() =>
            dispatch(analyticsClickExternalLink(BULK_PURCHASE_GIFTS_URL))
          }
          showExternalIcon
        />
      </Box>
      <Box>
        <Body>{translations.shareLabel}</Body>
      </Box>
      <Box mb={2}>
        <div
          data-grsf-block-form
          data-grsf-email={referralProps.email}
          data-grsf-copy-link-button-text={
            referralProps.translations.copyButton
          }
          data-grsf-button-style="{'background-color': '#0064DC', 'border': '1px solid #CACDD4', 'border-radius': '2px', 'color': '#FFFFFF', 'font-family': 'TT Norms', 'font-size': '16px', 'width': 'calc(100% - 5px)', 'margin-left': '5px'}"
          data-grsf-email-button-style="{'min-width': '115px','padding-left': '20px' , 'background-color': '#4A4A4A', 'color': '#FFFFFF', 'font-family': 'TT Norms', 'font-size': '18px'}"
          data-grsf-facebook-button-style="{'min-width': '115px','padding-left': '20px' , 'background-color': '#3B5998', 'color': '#FFFFFF', 'font-family': 'TT Norms', 'font-size': '18px'}"
          data-grsf-twitter-button-style="{'min-width': '115px','padding-left': '20px','margin-right': '5px' , 'background-color': '#1DA1F2;', 'color': '#FFFFFF', 'font-family': 'TT Norms', 'font-size': '18px'}"
          data-grsf-share-instructions="" // required else will create empty paragraph + headers with margin
          data-grsf-share-instructions-style="{'margin': '0 0 0 0'}"
        />
      </Box>
      <Box display={isDesktop && "flex"}>
        <Box mr={isDesktop && 1} mb={!isDesktop && 0.5}>
          <SmallExternalLink
            href={REFERRAL_PROGRAM_WILLFUL_ZENDESK_URL}
            text={translations.fullProgramFAQ}
            color={colorPalette.willfulGrey}
            onClick={() =>
              dispatch(
                analyticsClickExternalLink(
                  REFERRAL_PROGRAM_WILLFUL_ZENDESK_URL,
                ),
              )
            }
            showExternalIcon
          />
        </Box>
        <Box>
          <SmallExternalLink
            href={REFERRAL_PROGRAM_AMAZON_TERMS_AND_CONDITIONS}
            text={translations.restrictionsApply}
            color={colorPalette.willfulGrey}
            onClick={() =>
              dispatch(
                analyticsClickExternalLink(
                  REFERRAL_PROGRAM_AMAZON_TERMS_AND_CONDITIONS,
                ),
              )
            }
            showExternalIcon
          />
        </Box>
      </Box>
    </div>
  );
};
