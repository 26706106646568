import React from "react";
import Box from "@material-ui/core/Box";
import { SmallBody } from "../../ui/Typography";
import CheckmarkBlue from "../../ui/icons/CheckmarkBlue";
import GiftPlansIcon from "../../ui/icons/GiftPlanIcon";

const PlanDetails = ({ plansDetails }: PaymentSummaryPlanDetailsProp) => {
  return plansDetails.map((planDetail) => {
    const showGiftPlanIcon =
      planDetail.includes("Lifetime document access") ||
      planDetail.includes("Accès aux documents");

    return (
      <Box maxWidth="100%" mb={0.5} key={`${planDetail}`}>
        <Box component="span" display="flex">
          <Box mt="2px">
            {showGiftPlanIcon ? <GiftPlansIcon /> : <CheckmarkBlue />}
          </Box>
          <Box ml={0.5} pt={0.25}>
            <SmallBody
              key={planDetail}
              bold={showGiftPlanIcon ? true : false}
              align="left"
            >
              {planDetail}
            </SmallBody>
          </Box>
        </Box>
      </Box>
    );
  });
};

export default PlanDetails;
