import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@material-ui/core";
import DigitalExecutionButton from "./DigitalExecutionButton";

import { featureDigitalExecutionEnabled } from "../../../utilities/featureFlags";
import { selectDigitalExecutionStage } from "../../../selectors/digital-execution";
import { selectHasUserPaid } from "../../../selectors/plans";
import { selectIsUserFromBC } from "../../../selectors/provinces";
import { analyticsClickedAddonButton } from "../../../actions/analytics";
import { DIGITAL_EXECUTION_PURCHASE } from "../../../constants/addons";

export default function DigitalExecutionWrapper({ mt = 1.5, mb = 0 }) {
  if (!featureDigitalExecutionEnabled()) return null;

  const dispatch = useDispatch();

  const notPaid = !useSelector(selectHasUserPaid);
  const notBCUser = !useSelector(selectIsUserFromBC);
  const stage = useSelector(selectDigitalExecutionStage);

  const purchased = stage !== DIGITAL_EXECUTION_PURCHASE;

  function handleClick() {
    // TODO: Remove alert once we start integrating with backend.
    alert("Digital Execution: " + stage);

    dispatch(
      analyticsClickedAddonButton({ purchased, name: "Digital Execution" }),
    );
  }

  if (notPaid || notBCUser) return null;

  return (
    <Box mt={mt} mb={mb} width={1}>
      <DigitalExecutionButton stage={stage} onClick={handleClick} fullWidth />
    </Box>
  );
}
