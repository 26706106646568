import R from "ramda";
import { getFormValues } from "redux-form";
import { createStructuredSelector, createSelector } from "reselect";
import { getLocation } from "connected-react-router";
import { selectIsCurrentProvinceSelectedByCode } from "./provinces";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectTranslations } from "./translations";
import { selectIsFieldComplete } from "./utils/progress";
import {
  MARITAL_STATUS_FIELD_ID,
  MARITAL_STATUS_SINGLE,
  FIRST_NAME_FIELD_ID,
  MIDDLE_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
  EMAIL_FIELD_ID,
  SPOUSE_FORM_ID,
  MARITAL_STATUS_FORM_ID,
  MARITAL_STATUS_COMMON_LAW,
  SPOUSE_BASIC_INFO_FIELD_ID,
} from "../constants/forms";
import { spousePaths } from "../constants/navigation";
import { buildFullName } from "../utilities/name";

export const selectIsCurrentUserSpouse = createSelector(
  getLocation,
  ({ pathname }) => R.contains(pathname, spousePaths),
);

export const selectSpouse = R.path(["yourFamily", "spouse"]);
export const selectHasSpouse = R.path(["yourFamily", "needsSpouse"]);
const selectYourFamilyData = R.pathOr({}, ["yourFamily"]);

export const selectMaritalStatus = createSelector(
  selectYourFamilyData,
  R.prop(MARITAL_STATUS_FIELD_ID),
);
export const selectMaritalStatusForm = createSelector(
  selectMaritalStatus,
  (maritalStatus) => ({ [MARITAL_STATUS_FIELD_ID]: maritalStatus }),
);

export const selectSpouseFullName = createSelector(
  selectSpouse,
  (spouseData) => {
    const fullName = R.props([
      FIRST_NAME_FIELD_ID,
      MIDDLE_NAME_FIELD_ID,
      LAST_NAME_FIELD_ID,
    ])(spouseData);

    const [firstName, middleName, lastName] = fullName;
    return buildFullName(firstName, middleName, lastName);
  },
);

export const selectSpouseFirstName = createSelector(
  selectSpouse,
  R.propOr("", FIRST_NAME_FIELD_ID),
);

export const selectSpouseLastName = createSelector(
  selectSpouse,
  R.propOr("", LAST_NAME_FIELD_ID),
);

export const selectSpouseBasicInfo = createSelector(
  selectSpouse,
  R.props([
    FIRST_NAME_FIELD_ID,
    MIDDLE_NAME_FIELD_ID,
    LAST_NAME_FIELD_ID,
    EMAIL_FIELD_ID,
  ]),
);
export const selectSpouseBasicInfoForm = createSelector(
  selectSpouseBasicInfo,
  (basicInfoValues) => {
    const [firstName, middleName, lastName, email] = basicInfoValues;
    return {
      firstName,
      middleName,
      lastName,
      email,
    };
  },
);

export const selectSpouseBasicInfoFormValues = createSelector(
  getFormValues(SPOUSE_FORM_ID),
  (spouseBasicInfoFormData) => {
    return spouseBasicInfoFormData
      ? spouseBasicInfoFormData[SPOUSE_BASIC_INFO_FIELD_ID]
      : undefined;
  },
);

export const selectSpouseEmail = createSelector(
  selectSpouse,
  R.prop(EMAIL_FIELD_ID),
);

export const selectSpouseEmailForm = createSelector(
  selectSpouseEmail,
  (email) => ({ [EMAIL_FIELD_ID]: email }),
);

export const selectIsSpouseComplete = createSelector(
  selectMaritalStatus,
  selectIsFieldComplete(selectSpouse, FIRST_NAME_FIELD_ID),
  selectIsFieldComplete(selectSpouse, LAST_NAME_FIELD_ID),
  (maritalStatus, ...spouseFields) => {
    if (maritalStatus === MARITAL_STATUS_SINGLE) {
      return true;
    }

    return spouseFields.every((isComplete) => isComplete === true);
  },
);

export const selectIsSpouseBasicInfoComplete = createSelector(
  selectIsFieldComplete(selectSpouse, FIRST_NAME_FIELD_ID),
  selectIsFieldComplete(selectSpouse, LAST_NAME_FIELD_ID),
  (...allValues) => allValues.every((val) => val === true),
);

export const selectSpouseCurrentPage = createSelector(
  selectMaritalStatus,
  selectIsFieldComplete(selectYourFamilyData, MARITAL_STATUS_FIELD_ID),
  selectIsSpouseBasicInfoComplete,
  (maritalStatus, ...spouseFieldValues) => {
    if (maritalStatus === MARITAL_STATUS_SINGLE) {
      return 1;
    }

    return spouseFieldValues.reduce(
      (stepTotal, isComplete) => (isComplete ? stepTotal + 1 : stepTotal),
      0,
    );
  },
);

export const selectSpouseTotalPages = createSelector(
  selectMaritalStatus,
  (maritalStatus) => {
    if (!maritalStatus || maritalStatus === MARITAL_STATUS_SINGLE) {
      return 1;
    }

    return 2;
  },
);

// select Form Input not necessarily BE value
const selectFormIsCommonLaw = createSelector(
  getFormValues(MARITAL_STATUS_FORM_ID),
  (formValues) => {
    const maritalStatus = R.propOr("", MARITAL_STATUS_FIELD_ID)(formValues);
    return maritalStatus === MARITAL_STATUS_COMMON_LAW;
  },
);

export const selectMaritalStatusTranslations = createSelector(
  selectTranslations(["global", MARITAL_STATUS_FORM_ID]),
  selectIsCurrentProvinceSelectedByCode("QC"),
  selectFormIsCommonLaw,
  (translations, isQuebecSelected, isFormCommonLaw) => {
    if (isQuebecSelected && isFormCommonLaw) {
      return {
        ...translations,
        infoBox: [translations.commonLawInfoBox],
      };
    }
    return translations;
  },
);

export const maritalStatusPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(MARITAL_STATUS_FORM_ID),
  isLoading: selectIsLoadingById(MARITAL_STATUS_FORM_ID),
  translations: selectMaritalStatusTranslations,
  isQuebecSelected: selectIsCurrentProvinceSelectedByCode("QC"),
});

export const spousePageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(SPOUSE_FORM_ID),
  isLoading: selectIsLoadingById(SPOUSE_FORM_ID),
  translations: selectTranslations([
    "global",
    SPOUSE_FORM_ID,
    "navigation",
    "notice",
  ]),
});
