import React, { createContext, useState } from "react";

interface ModalContextType {
  mobileDrawer: boolean;
  setMobileDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ModalContextProviderProps {
  children: React.ReactNode;
}

const ModalContext = createContext<ModalContextType>({
  mobileDrawer: false,
  setMobileDrawer: () => {},
});

export function ModalContextProvider({ children }: ModalContextProviderProps) {
  const [mobileDrawer, setMobileDrawer] = useState(false);

  const ctxValue = { mobileDrawer, setMobileDrawer };

  return (
    <ModalContext.Provider value={ctxValue}>{children}</ModalContext.Provider>
  );
}

export default ModalContext;
