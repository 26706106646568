import { createStructuredSelector, createSelector } from "reselect";
import R from "ramda";

import { selectTranslations } from "./translations";
import { selectIsLoadingById } from "./requests";
import { formatCurrency } from "../utilities/helpers";
import { selectLanguageCode } from "./language";
import { selectCurrentRoute } from "./location";
import { selectHasUserPaid } from "./plans";
import {
  selectOrderHasDiscount,
  selectOrderPromoCode,
  selectOrderDiscountsData,
  selectOrderPartnerDiscount,
} from "./utils/discounts";

import { selectOrderItems, selectOrderSubtotalFormatted } from "./utils/order";

const selectItemCount = R.pathOr(0, ["cart", "itemCount"]);
export const selectAddonCount = R.pathOr(0, ["cart", "addonCount"]);
const selectAddonsInCart = (state) => state.cart;

// Regex required to replace multiple matches of quantity
// when using a string as parameter only first match is replaced
const quantityRegex = /QUANTITY/g;
const formatAdultLineItem = (quantity, price, languageCode, lineItemCopy) => {
  const priceFormatted = formatCurrency(price, languageCode, 2);
  return lineItemCopy
    .replace(quantityRegex, quantity)
    .replace("PRICE", priceFormatted);
};

const selectCartItemsFormatted = createSelector(
  selectOrderItems("cart"),
  selectLanguageCode,
  selectTranslations(["cart"]),
  (cartItems, languageCode, translations) =>
    cartItems.map((item) => {
      return {
        price: formatCurrency(item.price * item.quantity, languageCode, 2),
        adultsLineItem: formatAdultLineItem(
          item.quantity,
          item.price,
          languageCode,
          translations.adultsLineItem,
        ),
        quantity: item.quantity,
        isFamily: item.product.isFamily,
        symbol: item.product.symbol,
      };
    }),
);

export const selectAddonsFormatted = createSelector(
  selectAddonsInCart,
  selectLanguageCode,
  selectTranslations(["cart"]),
  (cartItem, languageCode) => {
    return cartItem.addons?.map((addon) => {
      const priceFormatted = formatCurrency(addon.totalPrice, languageCode, 2);
      return {
        id: addon.id,
        quantity: addon.quantity,
        price: priceFormatted,
        name: addon.addon.name,
        symbol: addon.addon.symbol,
      };
    });
  },
);

export const isAddonInCart = createSelector(selectAddonsInCart, (cartItem) => {
  return cartItem.addons?.length > 0;
});

// Since cartItems data is not returned in meta, but itemCount is
// there is some scenarios where the cart data has not been loaded
export const selectHasCartData = createSelector(
  selectItemCount,
  selectOrderItems("cart"),
  (itemCount, cartItems) => {
    if (itemCount > 0 && cartItems.length > 0) {
      return true;
    }
    return false;
  },
);

export const selectHasCartItems = createSelector(
  selectItemCount,
  selectOrderItems("cart"),
  (itemCount, cartItems) => {
    if (itemCount > 0 || cartItems.length > 0) {
      return true;
    }
    return false;
  },
);

export const cartProps = createStructuredSelector({
  itemCount: selectItemCount,
  hasCartData: selectHasCartData,
  isPaid: selectHasUserPaid,
  cart: createStructuredSelector({
    items: selectCartItemsFormatted,
    addonItems: selectAddonsFormatted,
    hasDiscount: selectOrderHasDiscount("cart"),
    hasAddons: isAddonInCart,
    discountsData: selectOrderDiscountsData("cart"),
    promoCode: selectOrderPromoCode("cart"),
    partnerDiscount: selectOrderPartnerDiscount("cart"),
    subtotal: selectOrderSubtotalFormatted("cart"),
  }),
  currentPath: selectCurrentRoute,
  translations: selectTranslations(["global", "cart"]),
  isLoading: selectIsLoadingById("cart"),
});
