import React from "react";

import DigitalExecutionAddon from "../ui/icons/DigitalExecutionAddon";

export const addonIcons = {
  digitalExecutionAddon: {
    icon: <DigitalExecutionAddon width={120} height={120} />,
    iconSmall: <DigitalExecutionAddon width={88} height={88} />,
    iconExtraSmall: <DigitalExecutionAddon width={60} height={60} />,
    cartIcon: <DigitalExecutionAddon width={48} height={48} />,
  },
};
