import { call, put, takeEvery, select } from "redux-saga/effects";

import { change, getFormValues, startSubmit, stopSubmit } from "redux-form";
import R from "ramda";
import { submitFormRequest } from "./forms";
import {
  getChildrenNextPage,
  removeChildEndpoint,
  submitChildren,
} from "../api/children";
import {
  childAddedSuccessfully,
  CHILDREN_NEXT_PAGE_TYPE,
  OPEN_MODAL_CHILDREN_FORM_TYPE,
  REMOVE_CHILD_TYPE,
  UPDATE_CHILDREN_TYPE,
} from "../actions/children";
import {
  CHILDREN_FIELD_ID,
  CHILDREN_FORM_ID,
  CHILD_MODAL_FORM_ID,
  CHILDREN_NAME_FIELD_ID,
  DOB_FIELD_ID,
  FIRST_PARENT_FIELD_ID,
  SECOND_PARENT_FIELD_ID,
} from "../constants/forms";
import { closeToast, displayToast } from "../actions/toast";
import { closeModal, displayModal } from "../actions/modal";
import {
  MODAL_ADD_CHILD_FORM,
  MODAL_EDIT_CHILD_FORM,
} from "../constants/modal";
import {
  ADD_CHILD_SUCCESS,
  EDIT_CHILD_SUCCESS,
  REMOVE_CHILD_SUCCESS,
} from "../constants/toasts";
import { selectLastSelectedChildData } from "../selectors/children";
import { DOBDefaultDate } from "../utilities/date";

function* updateChildren(childToUpdate) {
  const { status, child } = yield call(submitFormRequest, {
    apiCall: submitChildren,
    formId: CHILDREN_FORM_ID,
    values: {
      ...childToUpdate,
    },
  });
  if (status !== 200 || !child) {
    yield put(stopSubmit(CHILDREN_FORM_ID));
    return null;
  }
  return child.id;
}

function* handleOpenModalChildForm({ payload }) {
  yield put(closeToast());
  const children = R.propOr(null, "children")(payload);

  if (children) {
    const childrenNameData = {
      firstName: children.firstName || null,
      middleName: children.middleName || null,
      lastName: children.lastName || null,
      email: children.email || null,
    };
    yield put(
      change(CHILD_MODAL_FORM_ID, CHILDREN_NAME_FIELD_ID, childrenNameData),
    );
    yield put(change(CHILD_MODAL_FORM_ID, DOB_FIELD_ID, children.dateOfBirth));
    yield put(
      change(CHILD_MODAL_FORM_ID, FIRST_PARENT_FIELD_ID, children.firstParent),
    );
    yield put(
      change(
        CHILD_MODAL_FORM_ID,
        SECOND_PARENT_FIELD_ID,
        children.secondParent,
      ),
    );
    return yield put(displayModal(MODAL_EDIT_CHILD_FORM));
  }
  yield put(change(CHILD_MODAL_FORM_ID, DOB_FIELD_ID, DOBDefaultDate));
  return yield put(displayModal(MODAL_ADD_CHILD_FORM));
}

function* handleRemoveChild({ payload }) {
  const child = R.propOr(null, "child")(payload);
  yield put(closeToast());
  yield call(submitFormRequest, {
    apiCall: removeChildEndpoint,
    values: {
      ...child,
    },
  });
  yield put(displayToast(REMOVE_CHILD_SUCCESS));
}

export function* handleAddOrEditChildren(isEditing = false) {
  yield put(startSubmit(CHILD_MODAL_FORM_ID));
  try {
    const formData = yield select(getFormValues(CHILD_MODAL_FORM_ID));
    const { dateOfBirth, childrenName, firstParent, secondParent } = formData;
    const newChild = {
      firstName: childrenName.firstName || null,
      middleName: childrenName.middleName || null,
      lastName: childrenName.lastName || null,
      email: childrenName.email || null,
      dateOfBirth,
      firstParent,
      secondParent,
    };
    const updatedChildren = isEditing
      ? yield call(editChild, newChild)
      : yield call(addChild, newChild);
    yield put(closeModal());
    yield put(change(CHILDREN_FORM_ID, CHILDREN_FIELD_ID, updatedChildren));
    yield put(childAddedSuccessfully(newChild));
    yield put(displayToast(isEditing ? EDIT_CHILD_SUCCESS : ADD_CHILD_SUCCESS));
  } catch (error) {
    yield put(stopSubmit(CHILD_MODAL_FORM_ID, error.formErrors));
  }
}

function* addChild(newChild) {
  const childrenList = yield select(getFormValues(CHILDREN_FORM_ID));
  const id = yield call(updateChildren, newChild);
  const updatedChild = { id, ...newChild };
  return [...childrenList.children, updatedChild];
}

function* editChild(newChild) {
  const selectedChild = yield select(selectLastSelectedChildData);
  const { children } = yield select(getFormValues(CHILDREN_FORM_ID));
  let updatedChild = {};
  const newChildrenList = children.map((child, index) => {
    if (index === selectedChild.index) {
      updatedChild = {
        ...newChild,
        id: child.id,
      };
      return updatedChild;
    }
    return child;
  });
  yield call(updateChildren, updatedChild);
  return newChildrenList;
}

function* handleChildrenNextPage({ payload }) {
  const showZeroChildrenNotice = R.propOr(
    false,
    "showZeroChildrenNotice",
  )(payload);
  return yield call(submitFormRequest, {
    apiCall: getChildrenNextPage,
    values: {
      showZeroChildrenNotice,
    },
  });
}

export function* watchUpdateChildren() {
  yield takeEvery(UPDATE_CHILDREN_TYPE, updateChildren);
}

export function* watchHandleOpenModalAddChildren() {
  yield takeEvery(OPEN_MODAL_CHILDREN_FORM_TYPE, handleOpenModalChildForm);
}

export function* watchHandleRemoveChildren() {
  yield takeEvery(REMOVE_CHILD_TYPE, handleRemoveChild);
}

export function* watchChildrenNextPage() {
  yield takeEvery(CHILDREN_NEXT_PAGE_TYPE, handleChildrenNextPage);
}
