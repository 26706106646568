import { createSelector } from "reselect";

import { DIGITAL_EXECUTION_PURCHASE } from "../constants/addons";

const digitalExecutionStage = () => DIGITAL_EXECUTION_PURCHASE;

export const selectDigitalExecutionStage = createSelector(
  digitalExecutionStage,
  (stage) => stage,
);
