import {
  ADULT_CHILD_BADGE,
  CHILD_RELATIONSHIP,
  MINOR_CHILD_BADGE,
  SPOUSE_OR_PARTNER_RELATIONSHIP,
} from "../../../constants/contacts";

export const getContactBadgeKey = (person: ContactPayload) => {
  const { contact, isMinorChild } = person;
  if (!contact) return "";

  const { relationship } = contact;
  if (!relationship) return "";

  if (relationship === SPOUSE_OR_PARTNER_RELATIONSHIP) {
    return SPOUSE_OR_PARTNER_RELATIONSHIP;
  }

  if (relationship === CHILD_RELATIONSHIP) {
    return isMinorChild ? MINOR_CHILD_BADGE : ADULT_CHILD_BADGE;
  }

  return "";
};

export const getRelationshipList = (list: [] | {}) => {
  // Check if the input is an array or an object
  const listArray = Array.isArray(list)
    ? list
    : Object.entries(list).map(([key, value]) => ({
        value: key, // Use the key as the value
        label: value, // Use the value as the label
      }));
  // Map the array with value and label props
  return listArray.map(({ value, label }) => ({ value, label }));
};
