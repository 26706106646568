import React from "react";

import SvgIcon from "./SvgIcon";

const TulipIcon = ({ width = 19, height = 20, color = "#0064DC" }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 19 20" fill="none">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9.5 0a1 1 0 0 1 .768.36l1.873 2.247 1.734-1.388A1 1 0 0 1 15.5 2v4c0 .994-.29 2.461-1.188 3.71-.795 1.103-2.032 1.984-3.812 2.225v3.062a7.88 7.88 0 0 1 .588-.956C12.325 12.325 14.374 11 17.5 11a1 1 0 0 1 1 1c0 1.326-.39 3.294-1.588 4.96C15.675 18.674 13.626 20 10.5 20h-2c-3.126 0-5.176-1.325-6.412-3.04C.89 15.293.5 13.325.5 12a1 1 0 0 1 1-1c3.126 0 5.176 1.325 6.412 3.04.223.311.418.632.588.957v-3.062c-1.78-.241-3.017-1.122-3.812-2.226C3.79 8.461 3.5 6.994 3.5 6V2a1 1 0 0 1 1.625-.78l1.734 1.387L8.732.36A1 1 0 0 1 9.5 0ZM7.4 17.927c-.151-.863-.492-1.857-1.112-2.718-.727-1.01-1.87-1.885-3.689-2.136.152.863.493 1.857 1.113 2.718.727 1.01 1.87 1.885 3.689 2.136Zm4.2 0c1.818-.251 2.96-1.126 3.689-2.136.62-.861.96-1.855 1.112-2.719-1.82.252-2.962 1.127-3.69 2.137-.62.861-.96 1.855-1.112 2.718ZM9.5 2.562 7.768 4.64a1 1 0 0 1-1.393.14l-.875-.7V6c0 .673.21 1.706.812 2.54C6.876 9.326 7.826 10 9.5 10c1.674 0 2.624-.675 3.188-1.46.602-.834.812-1.866.812-2.54V4.08l-.875.7a1 1 0 0 1-1.393-.14L9.5 2.562Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

export default TulipIcon;
