import React from "react";

import { reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import { PrimaryButton } from "../../ui/Button";
import { ErrorMessage } from "../../ui/Error";
import NameFields from "../../ui/inputs/NameFields";
import {
  SPOUSE_NAME_FIELD_ID,
  SPOUSE_MODAL_FORM_ID,
} from "../../../constants/forms";

import { featureContactsEmailEnabled } from "../../../utilities/featureFlags";
import EmailField from "../../ui/inputs/EmailField";

const SpouseModal = ({
  error,
  invalid,
  handleSubmit,
  isLoading,
  translations,
  submitting,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="center" pt={2}>
        <NameFields
          translations={translations}
          fieldName={SPOUSE_NAME_FIELD_ID}
          useTheir
        />
        {featureContactsEmailEnabled() && (
          <EmailField
            useTheir
            autoFocus={false}
            translations={translations}
            optional
            fieldName={SPOUSE_NAME_FIELD_ID}
            infoText
          />
        )}
        <PrimaryButton
          fullWidth
          isLoading={isLoading}
          text={translations.button.submit}
          opacity={invalid ? 0.5 : 1}
          isDisabled={submitting}
        />
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: SPOUSE_MODAL_FORM_ID,
})(SpouseModal);
