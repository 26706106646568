import { putRequest, getRequest, deleteRequest } from "./request";
import {
  ALLOCATIONS_ENDPOINT,
  ALLOCATIONS_PRIMARY_ENDPOINT,
  ALLOCATIONS_PREDECEASE_ENDPOINT,
  BACKUPS_ENDPOINT,
  INHERITANCE_ENDPOINT,
  BACKUPS_NEXT_PAGE_ENDPOINT,
} from "../constants/routes";

export const submitAllocations = (config) =>
  putRequest(ALLOCATIONS_ENDPOINT, config);
export const submitPrimaryBeneficiary = (config) =>
  putRequest(ALLOCATIONS_PRIMARY_ENDPOINT, config);
export const submitPredecease = (config) =>
  putRequest(ALLOCATIONS_PREDECEASE_ENDPOINT, config);
export const submitInheritance = (config) =>
  putRequest(INHERITANCE_ENDPOINT, config);

export const getAllocations = (config) =>
  getRequest(ALLOCATIONS_ENDPOINT, config);

export const skipBackups = (config) => deleteRequest(BACKUPS_ENDPOINT, config);

export const submitBackups = (config) => putRequest(BACKUPS_ENDPOINT, config);

export const removeBackupEndpoint = (config) =>
  deleteRequest(BACKUPS_ENDPOINT, config);

export const getBackupsNextPage = (config) =>
  getRequest(BACKUPS_NEXT_PAGE_ENDPOINT, config);
