import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Body } from "../ui/Typography";
import CartWidget from "../ui/CartWidget";
import CloseWhiteIcon from "../ui/icons/CloseWhiteIcon";
import { MenuIcon } from "../ui/icons/MenuIcon";

import { selectHasUserPaid } from "../../selectors/plans";
import { selectHasCartItems, selectAddonCount } from "../../selectors/cart";
import { EarnAndReferWidget } from "../ui/EarnAndReferWidget";

const NavButton = styled.button`
  border: 0;
  background: #0064dc;
  outline: none;
  cursor: pointer;
  padding-right: 0;
`;

const MobileNav = ({
  isAuthenticated,
  isMobileNavOpen,
  setIsMobileNavOpen,
  cartProps,
  translations,
  analyticsOpenedCartWidget,
}) => {
  const hasUserPaid = useSelector(selectHasUserPaid);
  const cartHasItems = useSelector(selectHasCartItems);
  const shouldShowEarnAndReferWidget =
    isAuthenticated && hasUserPaid && !cartHasItems;
  useEffect(() => {
    document.body.style.overflow = isMobileNavOpen ? "hidden" : "initial";
  }, [isMobileNavOpen]);
  const addonCount = useSelector(selectAddonCount);
  const cartItemsCount = cartProps.itemCount > 0 || addonCount > 0;

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      component="nav"
    >
      <Box ml="auto" display="flex">
        {shouldShowEarnAndReferWidget && <EarnAndReferWidget />}
        {isAuthenticated ? (
          <Box display="flex">
            {cartItemsCount && (
              <CartWidget
                analyticsOpenedCartWidget={analyticsOpenedCartWidget}
                {...cartProps}
              />
            )}
            <NavButton
              type="button"
              aria-label={
                isMobileNavOpen ? translations.close : translations.menu
              }
              onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
            >
              <Body component="span" color="white">
                {isMobileNavOpen ? translations.close : translations.menu}
              </Body>
            </NavButton>
          </Box>
        ) : (
          <NavButton
            type="button"
            aria-label={
              isMobileNavOpen ? translations.close : translations.menu
            }
            onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
          >
            <Body component="span" color="white">
              {isMobileNavOpen ? <CloseWhiteIcon /> : <MenuIcon />}
            </Body>
          </NavButton>
        )}
      </Box>
    </Box>
  );
};

export default MobileNav;
