import React from "react";
import { useSelector } from "react-redux";

import { selectTranslations } from "../../../selectors/translations";

import MultiStageButton, { ButtonStages } from "../../ui/MultiStageButton";
import TulipIcon from "../../ui/icons/TulipIcon";

import {
  DIGITAL_EXECUTION_PURCHASE,
  DIGITAL_EXECUTION_BOOK,
  DIGITAL_EXECUTION_BOOKED,
  DIGITAL_EXECUTION_COMPLETE,
} from "../../../constants/addons";
import { useMediaQuery, useTheme } from "@material-ui/core";

const Stages = {
  purchase: DIGITAL_EXECUTION_PURCHASE,
  book: DIGITAL_EXECUTION_BOOK,
  booked: DIGITAL_EXECUTION_BOOKED,
  complete: DIGITAL_EXECUTION_COMPLETE,
};

export default function DigitalExecutionButton({
  stage,
  onClick,
  ...otherProps
}) {
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  const buttonText = useSelector(selectTranslations(["addons"]))[
    "digitalExecution"
  ]["button"];

  let tulip = <TulipIcon />;

  if (isTabletDown) {
    tulip = <TulipIcon width={17} height={18} />;
  }

  if (stage === Stages.book) {
    return (
      <MultiStageButton
        stage={ButtonStages.CTA}
        onClick={onClick}
        {...otherProps}
      >
        {tulip}
        {buttonText.book}
      </MultiStageButton>
    );
  }

  if (stage === Stages.booked) {
    return (
      <MultiStageButton stage={ButtonStages.COMPLETE} {...otherProps}>
        {buttonText.booked}
      </MultiStageButton>
    );
  }

  if (stage === Stages.complete) {
    return (
      <MultiStageButton stage={ButtonStages.COMPLETE} {...otherProps}>
        {buttonText.complete}
      </MultiStageButton>
    );
  }

  return (
    <MultiStageButton
      stage={ButtonStages.PURCHASE}
      onClick={onClick}
      {...otherProps}
    >
      {tulip}
      {buttonText.purchase}
    </MultiStageButton>
  );
}
