import R from "ramda";
import { takeEvery, call, select, put } from "redux-saga/effects";
import { change, startSubmit, stopSubmit, getFormValues } from "redux-form";

import {
  FETCH_SPOUSE_BASIC_INFO_TYPE,
  OPEN_MODAL_SPOUSE_TYPE,
  REMOVE_SPOUSE_TYPE,
  SPOUSE_NEXT_PAGE_TYPE,
  UPDATE_SPOUSE_TYPE,
} from "../actions/spouse";
import { getDataByFormId, submitFormRequest } from "./forms";
import { getSpouseNextPage, submitSpouse } from "../api/spouse";
import { selectHasLoaded } from "../selectors/requests";
import { fetchApiData } from "./requests";
import { getDashboard } from "../api/dashboard";
import {
  SPOUSE_BASIC_INFO_FIELD_ID,
  SPOUSE_FORM_ID,
  SPOUSE_MODAL_FORM_ID,
  SPOUSE_NAME_FIELD_ID,
} from "../constants/forms";
import { fetchSuccess } from "../actions/requests";
import { closeToast, displayToast } from "../actions/toast";
import { closeModal, displayModal } from "../actions/modal";
import { MODAL_ADD_SPOUSE, MODAL_EDIT_SPOUSE } from "../constants/modal";
import {
  ADD_SPOUSE_SUCCESS,
  EDIT_SPOUSE_SUCCESS,
  REMOVE_SPOUSE_SUCCESS,
} from "../constants/toasts";

function* updateSpouse({ payload }) {
  const { formId, spouseData } = payload;
  const formData = yield select(getFormValues(formId));
  if (spouseData || formData) {
    const spouseValues = spouseData || formData[SPOUSE_BASIC_INFO_FIELD_ID][0];
    const { status } = yield call(submitFormRequest, {
      apiCall: submitSpouse,
      formId,
      values: {
        ...spouseValues,
      },
      isSpouseValues: true,
    });

    if (status !== 200) {
      yield put(stopSubmit(formId));
      return status;
    }
  }
  return false;
}

function* fetchSpouseBasicInfo() {
  const hasLoaded = yield select(selectHasLoaded);
  if (!hasLoaded) {
    yield call(fetchApiData, {
      apiCall: getDashboard,
      SPOUSE_FORM_ID,
    });
  }
  const spouseFormData = yield call(getDataByFormId, SPOUSE_FORM_ID);
  if (
    spouseFormData &&
    spouseFormData.firstName !== "" &&
    spouseFormData.lastName !== ""
  ) {
    const spouse = {
      firstName: spouseFormData.firstName || null,
      middleName: spouseFormData.middleName || null,
      lastName: spouseFormData.lastName || null,
      email: spouseFormData.email || null,
    };
    yield put(change(SPOUSE_FORM_ID, SPOUSE_BASIC_INFO_FIELD_ID, [spouse]));
  }
  return yield put(fetchSuccess(SPOUSE_FORM_ID));
}

function* handleOpenModalSpouse({ payload }) {
  yield put(closeToast());
  const spouse = R.propOr(null, "spouse")(payload);

  if (spouse) {
    const spouseNameData = {
      firstName: spouse.firstName || null,
      middleName: spouse.middleName || null,
      lastName: spouse.lastName || null,
      email: spouse.email || null,
    };
    yield put(
      change(SPOUSE_MODAL_FORM_ID, SPOUSE_NAME_FIELD_ID, spouseNameData),
    );
    return yield put(displayModal(MODAL_EDIT_SPOUSE));
  }
  return yield put(displayModal(MODAL_ADD_SPOUSE));
}

export function* handleAddOrEditSpouse(isEditing = false) {
  yield put(startSubmit(SPOUSE_MODAL_FORM_ID));
  try {
    const formData = yield select(getFormValues(SPOUSE_MODAL_FORM_ID));
    const { spouseName } = formData;
    const newSpouse = {
      firstName: spouseName.firstName || null,
      middleName: spouseName.middleName || null,
      lastName: spouseName.lastName || null,
      email: spouseName.email || null,
    };
    const payload = {
      spouseData: newSpouse,
      formId: SPOUSE_MODAL_FORM_ID,
    };

    const error = yield call(updateSpouse, { payload });
    if (!error) {
      yield put(
        change(SPOUSE_FORM_ID, SPOUSE_BASIC_INFO_FIELD_ID, [newSpouse]),
      );
      yield put(closeModal());
      yield put(
        displayToast(isEditing ? EDIT_SPOUSE_SUCCESS : ADD_SPOUSE_SUCCESS),
      );
    }
  } catch (error) {
    yield put(stopSubmit(SPOUSE_MODAL_FORM_ID));
  }
}

function* handleRemoveSpouse() {
  yield put(closeToast());
  const payload = {
    spouseData: {},
    formId: SPOUSE_MODAL_FORM_ID,
  };

  const error = yield call(updateSpouse, { payload });
  if (!error) {
    yield put(displayToast(REMOVE_SPOUSE_SUCCESS));
  }
}

function* handleSpouseNextPage() {
  return yield call(submitFormRequest, {
    apiCall: getSpouseNextPage,
  });
}

export function* watchUpdateSpouse() {
  yield takeEvery(UPDATE_SPOUSE_TYPE, updateSpouse);
}

export function* watchFetchSpouseBasicInfo() {
  yield takeEvery(FETCH_SPOUSE_BASIC_INFO_TYPE, fetchSpouseBasicInfo);
}

export function* watchOpenModalSpouse() {
  yield takeEvery(OPEN_MODAL_SPOUSE_TYPE, handleOpenModalSpouse);
}

export function* watchRemoveSpouse() {
  yield takeEvery(REMOVE_SPOUSE_TYPE, handleRemoveSpouse);
}

export function* watchSpouseNextPage() {
  yield takeEvery(SPOUSE_NEXT_PAGE_TYPE, handleSpouseNextPage);
}
