import R from "ramda";
import { select, takeEvery, call, put } from "redux-saga/effects";
import {
  getFormValues,
  reset,
  change,
  startSubmit,
  stopSubmit,
} from "redux-form";
import { setDisplayAllocationHelper } from "../actions/allocations-slider";
import {
  cancelAddingBeneficiary,
  ADD_BENEFICIARY_TYPE,
  ADD_BACKUP_BENEFICIARY_TYPE,
  BENEFICIARY_CANCEL_ADDING_TYPE,
  addPersonAllocation,
  addPersonBackup,
  addCharityAllocationAction,
  addCharityBackupAction,
  allocationUpdatedSuccessfully,
  allocationRemovedSuccessfully,
} from "../actions/beneficiary";
import { distributeAllocationsEvenly } from "../actions/allocations";

import {
  BACKUPS_FORM_ID,
  ADD_BENEFICIARY_FORM_ID,
  DONATIONS_LIST_CHARITY,
  DONATIONS_CUSTOM_CHARITY,
  BACKUPS_FIELD_ID,
  PERSON_ALLOCATION,
  ADD_PERSON_BENEFICIARY_FIELD_ID,
  CHARITY_ALLOCATION,
  ADD_CHARITY_BENEFICIARY_FIELD_ID,
  PERSON_BACKUP,
  CHARITY_BACKUP,
  PERSON_ALLOCATION_FORM_ID,
  ADD_PERSON_BACKUP_FORM_ID,
  CUSTOM_CHARITY_FORM_ID,
  CHARITY_ID_FIELD_ID,
  BUSINESS_NUMBER_FIELD_ID,
  REGISTERED_NAME_FIELD_ID,
  ALLOCATIONS_FORM_ID,
  ALLOCATIONS_FIELD_ID,
  CHARITY_FORM_ID,
} from "../constants/forms";
import { selectFormAllocations } from "../selectors/allocations";
import { selectFormBackups } from "../selectors/backup";
import {
  selectCharitiesOptionList,
  selectCharities,
} from "../selectors/donations";
import { selectValidationErrorsTranslations } from "../selectors/utils/validate";
import { closeModal } from "../actions/modal";
import { displayToast } from "../actions/toast";
import {
  ADD_PERSON_ALLOCATION_SUCCESS,
  ADD_CHARITY_ALLOCATION_SUCCESS,
  ADD_PERSON_BACKUP_SUCCESS,
  ADD_CHARITY_BACKUP_SUCCESS,
  UPDATED_ALLOCATION_SUCCESS,
  REMOVED_ALLOCATION_SUCCESS,
} from "../constants/toasts";
import { selectCurrentRoute } from "../selectors/location";
import { BACKUPS_PATH } from "../constants/routes";
import { selectCurrentAllocationCardIndex } from "../selectors/beneficiary";
import {
  removeAllocationFromList,
  getAllocationName,
  distributeAmountsEvenly,
} from "../utilities/allocations";
import { handleUpdateCharityBackupOnProgress } from "./allocations";

// TODO: This method will be deprecated when mini-forms are released
function* deprecatedAddBackupBeneficiary() {
  const formValues = yield select(getFormValues(ADD_BENEFICIARY_FORM_ID));
  const translations = yield select(selectValidationErrorsTranslations);
  const currentBackups = yield select(selectFormBackups);

  const person = formValues[ADD_PERSON_BENEFICIARY_FIELD_ID];
  const charity = formValues[ADD_CHARITY_BENEFICIARY_FIELD_ID];

  try {
    if (person) {
      const personBackup = {
        ...person,
        type: PERSON_BACKUP,
      };
      yield call(validateUniquePerson, person, currentBackups, translations);
      const newBackups = [...currentBackups, personBackup];
      yield put(change(BACKUPS_FORM_ID, BACKUPS_FIELD_ID, newBackups));
    } else if (charity) {
      const { entryType, registeredName, charityId, businessNumber } = charity;

      const charityBackup = {
        type: CHARITY_BACKUP,
        entryType,
      };
      yield call(validateUniqueCharity, charity, currentBackups, translations);
      if (entryType === DONATIONS_LIST_CHARITY) {
        charityBackup.charityId = charityId;
      }
      if (entryType === DONATIONS_CUSTOM_CHARITY) {
        charityBackup.registeredName = registeredName;
        charityBackup.businessNumber = businessNumber;
      }
      const newBackups = [...currentBackups, charityBackup];
      yield put(change(BACKUPS_FORM_ID, BACKUPS_FIELD_ID, newBackups));
    }

    yield put(reset(ADD_BENEFICIARY_FORM_ID));
    yield put(cancelAddingBeneficiary());
  } catch (error) {
    yield put(stopSubmit(ADD_BENEFICIARY_FORM_ID, error.formErrors));
  }
}

function* addBeneficiary() {
  const addBeneFormValues = yield select(
    getFormValues(ADD_BENEFICIARY_FORM_ID),
  );
  const allocations = yield select(selectFormAllocations);
  const person = addBeneFormValues[ADD_PERSON_BENEFICIARY_FIELD_ID];
  const charity = addBeneFormValues[ADD_CHARITY_BENEFICIARY_FIELD_ID];
  const translations = yield select(selectValidationErrorsTranslations);

  yield put(startSubmit(ADD_BENEFICIARY_FORM_ID));
  try {
    if (person) {
      yield call(validateUniquePerson, person, allocations, translations);
      yield call(updatePersonAllocations, person, allocations);
    } else if (charity) {
      yield call(validateUniqueCharity, charity, allocations, translations);
      yield call(deprecatedAddCharityAllocation, charity, allocations);
    }
    if (allocations.length === 1) {
      yield put(setDisplayAllocationHelper(true));
    }
    yield put(reset(ADD_BENEFICIARY_FORM_ID));
    yield put(cancelAddingBeneficiary());
  } catch (error) {
    yield put(stopSubmit(ADD_BENEFICIARY_FORM_ID, error.formErrors));
  }
}

export function* handleAddBackupAllocation() {
  const translations = yield select(selectValidationErrorsTranslations);

  yield put(startSubmit(ADD_PERSON_BACKUP_FORM_ID));
  const formValues = yield select(getFormValues(ADD_PERSON_BACKUP_FORM_ID));
  const person = formValues[ADD_PERSON_BENEFICIARY_FIELD_ID];
  const currentBackups = yield select(selectFormBackups);

  try {
    const personBackup = {
      ...person,
      type: PERSON_BACKUP,
    };

    yield put(addPersonBackup(person));
    yield call(validateUniquePerson, person, currentBackups, translations);
    const newBackups = [...currentBackups, personBackup];
    yield put(change(BACKUPS_FORM_ID, BACKUPS_FIELD_ID, newBackups));

    yield put(reset(ADD_PERSON_BACKUP_FORM_ID));
    yield put(cancelAddingBeneficiary());
    yield put(displayToast(ADD_PERSON_BACKUP_SUCCESS));
    yield put(closeModal());
  } catch (error) {
    yield put(stopSubmit(ADD_PERSON_BACKUP_FORM_ID, error.formErrors));
  }
}

export function* handleAddPersonAllocation() {
  const translations = yield select(selectValidationErrorsTranslations);

  yield put(startSubmit(PERSON_ALLOCATION_FORM_ID));
  try {
    const formValues = yield select(getFormValues(PERSON_ALLOCATION_FORM_ID));
    const person = formValues[ADD_PERSON_BENEFICIARY_FIELD_ID];
    const allocations = yield select(selectFormAllocations);
    yield call(validateUniquePerson, person, allocations, translations);
    yield call(updatePersonAllocations, person, allocations);
    if (allocations.length === 1) {
      yield put(setDisplayAllocationHelper(true));
    }

    yield put(reset(PERSON_ALLOCATION_FORM_ID));
    yield put(cancelAddingBeneficiary());

    yield put(displayToast(ADD_PERSON_ALLOCATION_SUCCESS));
    yield put(closeModal());
  } catch (error) {
    yield put(stopSubmit(PERSON_ALLOCATION_FORM_ID, error.formErrors));
  }
}

export function* handleUpdatePersonAllocation() {
  const translations = yield select(selectValidationErrorsTranslations);

  yield put(startSubmit(PERSON_ALLOCATION_FORM_ID));
  try {
    const formValues = yield select(getFormValues(PERSON_ALLOCATION_FORM_ID));
    const updatedAllocationCard = formValues[ADD_PERSON_BENEFICIARY_FIELD_ID];
    const allocationCardIndex = yield select(selectCurrentAllocationCardIndex);

    const { allocations } = yield select(getFormValues(ALLOCATIONS_FORM_ID));
    const updatedAllocations = allocations.map((allocation, index) => {
      if (index === allocationCardIndex) {
        return {
          ...updatedAllocationCard,
        };
      }
      return allocation;
    });
    const allocationsWithoutUpdatedCard = allocations.filter(
      (allocation, index) => index !== allocationCardIndex,
    );
    yield call(
      validateUniquePerson,
      updatedAllocationCard,
      allocationsWithoutUpdatedCard,
      translations,
    );

    yield put(
      change(ALLOCATIONS_FORM_ID, ALLOCATIONS_FIELD_ID, updatedAllocations),
    );

    const personName = `${updatedAllocationCard.firstName} ${updatedAllocationCard.lastName}`;
    yield put(allocationUpdatedSuccessfully(personName));
    yield put(displayToast(UPDATED_ALLOCATION_SUCCESS));

    yield put(closeModal());
  } catch (error) {
    yield put(stopSubmit(PERSON_ALLOCATION_FORM_ID, error.formErrors));
  }
}

export function* handleUpdateCharityAllocation() {
  const translations = yield select(selectValidationErrorsTranslations);

  yield put(startSubmit(CHARITY_FORM_ID));
  try {
    const charity = yield select(getFormValues(CHARITY_FORM_ID));
    const { charityId } = charity;
    const allocationCardIndex = yield select(selectCurrentAllocationCardIndex);

    const { allocations } = yield select(getFormValues(ALLOCATIONS_FORM_ID));
    const updatedAllocations = allocations.map((allocation, index) => {
      if (index === allocationCardIndex) {
        return {
          ...allocation,
          charityId,
        };
      }
      return allocation;
    });
    const allocationsWithoutUpdatedCard = allocations.filter(
      (allocation, index) => index !== allocationCardIndex,
    );

    yield call(
      validateUniqueCharity,
      charity,
      allocationsWithoutUpdatedCard,
      translations,
    );

    yield put(
      change(ALLOCATIONS_FORM_ID, ALLOCATIONS_FIELD_ID, updatedAllocations),
    );

    const charitiesOptionList = yield select(selectCharitiesOptionList);
    const charityOption = charitiesOptionList.find(
      (c) => c.value === charityId,
    );
    yield put(allocationUpdatedSuccessfully(charityOption.label));
    yield put(displayToast(UPDATED_ALLOCATION_SUCCESS));

    yield put(closeModal());
  } catch (error) {
    yield put(stopSubmit(CHARITY_FORM_ID, error.formErrors));
  }
}

export function* handleUpdateCustomCharityAllocation() {
  const translations = yield select(selectValidationErrorsTranslations);

  yield put(startSubmit(CUSTOM_CHARITY_FORM_ID));
  try {
    const charity = yield select(getFormValues(CUSTOM_CHARITY_FORM_ID));
    const { businessNumber, registeredName } = charity;
    const allocationCardIndex = yield select(selectCurrentAllocationCardIndex);

    const { allocations } = yield select(getFormValues(ALLOCATIONS_FORM_ID));
    const updatedAllocations = allocations.map((allocation, index) => {
      if (index === allocationCardIndex) {
        return {
          ...allocation,
          businessNumber,
          registeredName,
        };
      }
      return allocation;
    });
    const allocationsWithoutUpdatedCard = allocations.filter(
      (allocation, index) => index !== allocationCardIndex,
    );

    yield call(
      validateUniqueCharity,
      charity,
      allocationsWithoutUpdatedCard,
      translations,
    );

    yield put(
      change(ALLOCATIONS_FORM_ID, ALLOCATIONS_FIELD_ID, updatedAllocations),
    );

    yield put(allocationUpdatedSuccessfully(registeredName));
    yield put(displayToast(UPDATED_ALLOCATION_SUCCESS));

    yield put(closeModal());
  } catch (error) {
    yield put(stopSubmit(CUSTOM_CHARITY_FORM_ID, error.formErrors));
  }
}

export function* handleAddCharity(formId) {
  const translations = yield select(selectValidationErrorsTranslations);
  const currentRoute = yield select(selectCurrentRoute);
  const charity = yield call(buildCharityPayload, formId);

  // Check if it is adding charities from '/backups' page
  const isBackupsForm = currentRoute === BACKUPS_PATH;

  yield put(startSubmit(formId));
  try {
    if (isBackupsForm) {
      const backups = yield select(selectFormBackups);
      yield put(addCharityBackupAction(charity));
      yield call(validateUniqueCharity, charity, backups, translations);
      yield call(addCharityBackup, charity, backups);
    } else {
      const allocations = yield select(selectFormAllocations);
      yield put(addCharityAllocationAction(charity));
      yield call(validateUniqueCharity, charity, allocations, translations);
      yield call(addCharityAllocation, charity, allocations);
    }

    yield put(reset(formId));
    yield put(cancelAddingBeneficiary());

    yield put(
      displayToast(
        isBackupsForm
          ? ADD_CHARITY_BACKUP_SUCCESS
          : ADD_CHARITY_ALLOCATION_SUCCESS,
      ),
    );

    yield put(closeModal());
  } catch (error) {
    yield put(stopSubmit(formId, error.formErrors));
  }
}

export function* handleAddCustomCharityOnProgress() {
  const charity = yield call(buildCharityPayload, CUSTOM_CHARITY_FORM_ID);
  yield put(startSubmit(CUSTOM_CHARITY_FORM_ID));
  try {
    const payload = {
      backup: charity,
      charityId: null,
      isCustomInput: true,
    };
    yield call(handleUpdateCharityBackupOnProgress, { payload });
  } catch (error) {
    yield put(stopSubmit(CUSTOM_CHARITY_FORM_ID, error.formErrors));
  }
}

export function* handleRemoveBeneficiary() {
  const allocationCardIndex = yield select(selectCurrentAllocationCardIndex);
  if (typeof allocationCardIndex !== "number") return;
  const formAllocations = yield select(selectFormAllocations);
  const allocation = formAllocations[allocationCardIndex];
  const newAllocations = removeAllocationFromList(allocation, formAllocations);
  const evenlyDistributedAllocations = yield call(
    distributeAmountsEvenly,
    newAllocations,
  );

  const charitiesList = yield select(selectCharities);
  const beneficiaryName = getAllocationName(allocation, charitiesList);
  yield put(allocationRemovedSuccessfully(beneficiaryName));
  yield put(displayToast(REMOVED_ALLOCATION_SUCCESS));

  yield put(
    change(
      ALLOCATIONS_FORM_ID,
      ALLOCATIONS_FIELD_ID,
      evenlyDistributedAllocations,
    ),
  );
  yield put(closeModal());
}

function* buildCharityPayload(formId) {
  const formValues = yield select(getFormValues(formId));
  let charity = {};

  if (formId === CUSTOM_CHARITY_FORM_ID) {
    const businessNumber = R.propOr("", BUSINESS_NUMBER_FIELD_ID)(formValues);
    const formNameInput = R.propOr("", REGISTERED_NAME_FIELD_ID)(formValues);
    const registeredName = formNameInput.trim();
    charity = {
      businessNumber,
      registeredName,
      charityName: registeredName, // name to display on toast notification
      entryType: DONATIONS_CUSTOM_CHARITY,
    };
  } else {
    const charitiesOptionList = yield select(selectCharitiesOptionList);
    const charityId = R.propOr(0, CHARITY_ID_FIELD_ID)(formValues);
    const charityOption = charitiesOptionList.find(
      (c) => c.value === charityId,
    );
    charity = {
      charityId,
      charityName: charityOption.label, // name to display on toast notification
      entryType: DONATIONS_LIST_CHARITY,
    };
  }
  return charity;
}

const nameMatch = ({ firstName, middleName = null, lastName }) => (person) => {
  if (middleName) {
    return (
      firstName === person.firstName &&
      middleName === person.middleName &&
      lastName === person.lastName
    );
  }
  return firstName === person.firstName && lastName === person.lastName;
};

const charityMatch = ({ registeredName, charityId }) => (charity) =>
  (registeredName && registeredName === charity.registeredName) ||
  (charityId && charityId === charity.charityId);

function validateUniquePerson(person, allocations, translations) {
  const isInvalid = R.any(nameMatch(person), allocations);
  if (isInvalid) {
    const validationError = new Error();
    validationError.formErrors = {
      _error: translations["validation.identicalBeneficiaries"],
    };
    throw validationError;
  }
}

function validateUniqueCharity(charity, list, translations) {
  const isInvalid = R.any(charityMatch(charity), list);
  if (isInvalid) {
    const validationError = new Error(translations["validation.uniqueCharity"]);
    validationError.formErrors = {
      _error: translations["validation.identicalCharities"],
    };
    throw validationError;
  }
}

function* updatePersonAllocations(person, allocations) {
  const updatedAllocations = [
    ...allocations,
    {
      ...person,
      type: PERSON_ALLOCATION,
    },
  ];
  yield put(addPersonAllocation(person));
  yield put(distributeAllocationsEvenly(updatedAllocations));
}

// TODO: This method will be deprecated when mini-forms are released
function* deprecatedAddCharityAllocation(charity, allocations) {
  const { entryType, registeredName, charityId, businessNumber } = charity;

  const charityAllocation = {
    type: CHARITY_ALLOCATION,
    entryType,
  };
  if (entryType === DONATIONS_LIST_CHARITY) {
    charityAllocation.charityId = charityId;
  }
  if (entryType === DONATIONS_CUSTOM_CHARITY) {
    charityAllocation.registeredName = registeredName;
    charityAllocation.businessNumber = businessNumber;
  }

  const updatedAllocations = [...allocations, charityAllocation];
  yield put(distributeAllocationsEvenly(updatedAllocations));
}

function* addCharityAllocation(charity, allocations) {
  const charityAllocation = {
    type: CHARITY_ALLOCATION,
    ...charity,
  };
  const updatedAllocations = [...allocations, charityAllocation];
  yield put(distributeAllocationsEvenly(updatedAllocations));
}

function* addCharityBackup(charity, backups) {
  const charityBackups = {
    type: CHARITY_BACKUP,
    ...charity,
  };
  const updatedBackups = [...backups, charityBackups];
  yield put(change(BACKUPS_FORM_ID, BACKUPS_FIELD_ID, updatedBackups));
}

function* cancelAdding() {
  yield put(reset(ADD_BENEFICIARY_FORM_ID));
}

export function* watchAddBeneficiary() {
  yield takeEvery(ADD_BENEFICIARY_TYPE, addBeneficiary);
}

export function* watchAddBackupBeneficiary() {
  yield takeEvery(ADD_BACKUP_BENEFICIARY_TYPE, deprecatedAddBackupBeneficiary);
}

export function* watchCancelAdding() {
  yield takeEvery(BENEFICIARY_CANCEL_ADDING_TYPE, cancelAdding);
}
