import React, { useEffect, useState, Fragment } from "react";
import DropdownSelect from "./DropdownSelect";
import { RELATIONSHIP_FIELD_ID } from "../../../constants/forms";
import { Field } from "redux-form";
import { getRelationshipList } from "../utils/contacts";
import { useValidateContactRelationshipPresentMemo } from "./validations";

interface RelationshipFieldProps {
  contactData?: Contact;
  fieldName: string;
  disabled?: boolean;
  translations: {
    global: {};
    relationship: RelationshipTranslationsType;
  };
}

const RelationshipField = ({
  contactData,
  fieldName,
  disabled = false,
  translations,
}: RelationshipFieldProps) => {
  const [relationshipValue, setRelationshipValue] = useState("");
  useEffect(() => {
    if (contactData) {
      setRelationshipValue(contactData?.relationship || "");
    }
  }, [contactData]);
  return (
    /* FIX: the typescript error of redux-from Field not being recognized as a JSX component */
    /* @ts-ignore */
    <Field
      component={RelationshipDropdown}
      translations={translations.relationship}
      name={`${fieldName}.${RELATIONSHIP_FIELD_ID}`}
      type="select"
      validate={useValidateContactRelationshipPresentMemo(translations.global)}
      value={relationshipValue}
      disabled={disabled}
    />
  );
};

const RelationshipDropdown = React.memo(
  (fieldData: RelationshipDropdownFieldProps) => (
    <DropdownSelect
      input={fieldData.input}
      optionList={getRelationshipList(fieldData.translations.list)}
      label={fieldData.translations.label}
      placeholder={fieldData.translations.placeholder}
      disabled={fieldData.disabled}
      shouldDisplayError={true}
      meta={fieldData.meta}
    />
  ),
);

export default RelationshipField;
