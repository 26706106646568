import React from "react";

import { Field, reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import { PrimaryButton, TextButton } from "../../ui/Button";
import { ErrorMessage } from "../../ui/Error";

import {
  CUSTOM_CHARITY_FORM_ID,
  BUSINESS_NUMBER_FIELD_ID,
  REGISTERED_NAME_FIELD_ID,
} from "../../../constants/forms";
import { useValidateCharityNameMemo } from "../../ui/inputs/validations";
import TextInput from "../../ui/inputs/TextInput";
import { featureAssociateContactsEnabled } from "../../../utilities/featureFlags";

const CancelButton = ({ closeModal, translations }) => (
  <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
    <TextButton text={translations.button.cancel} onClick={closeModal} />
  </Box>
);

const CustomCharityAllocationForm = ({
  error,
  handleSubmit,
  isLoading,
  translations,
  closeModal,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="center" pt={2}>
        <Field
          name={REGISTERED_NAME_FIELD_ID}
          label={translations.charityNameLabel}
          component={TextInput}
          validate={useValidateCharityNameMemo(translations)}
          placeholder={translations.charityNamePlaceHolder}
        />
        <Field
          name={BUSINESS_NUMBER_FIELD_ID}
          label={translations.charityBusinessNumberLabel}
          component={TextInput}
          placeholder={translations.charityBusinessNumberPlaceHolder}
        />
        <Box>
          <PrimaryButton
            fullWidth
            isLoading={isLoading}
            text={translations.button.submit}
          />
        </Box>
        <ErrorMessage error={error} />
        {!featureAssociateContactsEnabled() && (
          <CancelButton translations={translations} closeModal={closeModal} />
        )}
      </Box>
    </form>
  );
};

export default reduxForm({
  form: CUSTOM_CHARITY_FORM_ID,
})(CustomCharityAllocationForm);
