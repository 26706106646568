import React from "react";
import styled from "styled-components";

import { StyledSecondaryButton, StyledColoredButton } from "./Button";
import { Body } from "./Typography";
import { colorPalette } from "../../config/Theme";

export const ButtonStages = {
  PURCHASE: "PURCHASE",
  CTA: "CTA",
  COMPLETE: "COMPLETE",
};

const MultiStagePrimary = styled(StyledColoredButton)`
  .multi-stage-button-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`;

const MultiStageSecondary = styled(StyledSecondaryButton)`
  color: ${colorPalette.willfulBlue};
  border-radius: 0;

  &:disabled {
    color: ${colorPalette.willfulLightBlue};
    border-color: ${colorPalette.willfulLightBlue};
  }

  .multi-stage-button-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`;

export default function MultiStageButton({
  children,
  stage,
  onClick,
  ...otherProps
}) {
  if (stage === ButtonStages.CTA) {
    return (
      <MultiStageSecondary onClick={onClick} {...otherProps}>
        <Body
          component="span"
          className="default-margin multi-stage-button-inner"
        >
          {children}
        </Body>
      </MultiStageSecondary>
    );
  }

  if (stage === ButtonStages.COMPLETE) {
    return (
      <MultiStageSecondary disabled {...otherProps}>
        <Body
          component="span"
          className="default-margin multi-stage-button-inner"
        >
          {children}
        </Body>
      </MultiStageSecondary>
    );
  }

  return (
    <MultiStagePrimary onClick={onClick} {...otherProps}>
      <Body
        component="span"
        className="default-margin multi-stage-button-inner"
      >
        {children}
      </Body>
    </MultiStagePrimary>
  );
}
