import React from "react";

import SvgIcon from "./SvgIcon";

export const ExternalIcon = ({ size = 24, color = "#0064DC" }) => {
  return (
    <SvgIcon width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M13.1429 5.14286C13.1429 4.53571 13.6429 4 14.2857 4H18.8214C19 4 19.1429 4.03571 19.2857 4.10714C19.3929 4.14286 19.5357 4.25 19.6429 4.35714C19.8571 4.57143 19.9643 4.85714 20 5.14286V9.71429C20 10.3571 19.4643 10.8571 18.8571 10.8571C18.2143 10.8571 17.7143 10.3571 17.7143 9.71429V7.92857L11.6429 13.9643C11.2143 14.4286 10.4643 14.4286 10.0357 13.9643C9.57143 13.5357 9.57143 12.7857 10.0357 12.3571L16.0714 6.28571H14.2857C13.6429 6.28571 13.1429 5.78571 13.1429 5.14286ZM4 7.42857C4 6.17857 5 5.14286 6.28571 5.14286H9.71429C10.3214 5.14286 10.8571 5.67857 10.8571 6.28571C10.8571 6.92857 10.3214 7.42857 9.71429 7.42857H6.28571V17.7143H16.5714V14.2857C16.5714 13.6786 17.0714 13.1429 17.7143 13.1429C18.3214 13.1429 18.8571 13.6786 18.8571 14.2857V17.7143C18.8571 19 17.8214 20 16.5714 20H6.28571C5 20 4 19 4 17.7143V7.42857Z"
        fill={color}
      />
    </SvgIcon>
  );
};
