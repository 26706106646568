import React, { useEffect } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { connect } from "react-redux";

import { FormWrapper } from "../../../components/ui/Form";
import {
  backupsNextPage,
  fetchBackup,
  removeBackup,
  updateBackup,
} from "../../../actions/allocations";
import { backupPageProps } from "../../../selectors/backup";
import { BACKUPS_GUIDANCE_PATH } from "../../../constants/routes";
import {
  addBackupBeneficiary,
  addCharityBeneficiary,
  addPersonBeneficiary,
  cancelAddingBeneficiary,
} from "../../../actions/beneficiary";

import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { displayModal } from "../../../actions/modal";
import BackupsForm from "../../../components/forms/your-estate/BackupsForm";

const BackupPage = ({
  addBackupBeneficiary,
  fetchBackup,
  removeBackup,
  updateBackup,
  hasLoaded,
  backupForm,
  addBeneficiaryForm,
  hasSelectedBackup,
  backupsNextPage,
  ...restOfProps
}) => {
  useEffect(() => {
    fetchBackup();
  }, [fetchBackup]);
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  if (!hasLoaded) return <LoadingSpinner />;
  const { translations } = restOfProps;

  return (
    <FormWrapper
      includePadding
      translations={translations}
      reverseWrap={isTabletDown}
    >
      <BackupsForm
        onSubmit={() => backupsNextPage()}
        onSkip={() => backupsNextPage()}
        removeBackup={removeBackup}
        backLink={BACKUPS_GUIDANCE_PATH}
        {...restOfProps}
        {...backupForm}
      />
    </FormWrapper>
  );
};

export default connect(backupPageProps, {
  addBackupBeneficiary,
  fetchBackup,
  removeBackup,
  updateBackup,
  addCharityBeneficiary,
  addPersonBeneficiary,
  cancelAddingBeneficiary,
  displayModal,
  backupsNextPage,
})(BackupPage);
