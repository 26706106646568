import R from "ramda";

import { UPDATE_STATUS_TYPE } from "../actions/status";
import {
  GIFTS_FIELD_ID,
  DONATIONS_FIELD_ID,
  PRIMARY_BENEFICIARY_FIELD_ID,
  PREDECEASE_FIELD_ID,
  INHERITANCE_FIELD_ID,
  AGE_MILESTONE_FIELD_ID,
  INHERITANCE_AGES_FIELD_ID,
  ALLOCATIONS_FIELD_ID,
  BACKUPS_FIELD_ID,
  SHARE_DONATION_DETAIL_FIELD_ID,
} from "../constants/forms";

const DEFAULT_STATE = {
  gifts: [],
  donations: [],
  allocations: [],
  giftsVisited: false,
  donationsVisited: false,
  backupsVisited: false,
};

const yourEstate = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return {
        ...state,
        gifts: R.pathOr([], ["user", GIFTS_FIELD_ID])(payload),
        giftsVisited: R.pathOr(false, ["user", "giftsVisited"])(payload),
        donations: R.pathOr([], ["user", DONATIONS_FIELD_ID])(payload),
        donationsVisited: R.pathOr(false, ["user", "donationsVisited"])(
          payload,
        ),
        [PRIMARY_BENEFICIARY_FIELD_ID]: R.propOr(
          "",
          PRIMARY_BENEFICIARY_FIELD_ID,
        )(payload),
        [ALLOCATIONS_FIELD_ID]: R.propOr(
          [],
          ALLOCATIONS_FIELD_ID,
        )(payload).sort((a, b) => a.id - b.id),
        [PREDECEASE_FIELD_ID]: R.propOr("", PREDECEASE_FIELD_ID)(payload),
        [INHERITANCE_FIELD_ID]: R.propOr("", INHERITANCE_FIELD_ID)(payload),
        [AGE_MILESTONE_FIELD_ID]: R.propOr("", AGE_MILESTONE_FIELD_ID)(payload),
        [INHERITANCE_AGES_FIELD_ID]: R.propOr(
          "",
          INHERITANCE_AGES_FIELD_ID,
        )(payload),
        [BACKUPS_FIELD_ID]: R.propOr(
          [],
          BACKUPS_FIELD_ID,
        )(payload).sort((a, b) => a.id - b.id),
        backupsVisited: R.propOr(false, "backupsVisited")(payload),
        [SHARE_DONATION_DETAIL_FIELD_ID]: R.pathOr(false, [
          "user",
          SHARE_DONATION_DETAIL_FIELD_ID,
        ])(payload),
      };
    default:
      return state;
  }
};

export default yourEstate;
