import React from "react";
import { reduxForm, InjectedFormProps, formValues } from "redux-form";
import { useSelector, TypedUseSelectorHook, RootStateOrAny } from "react-redux";

import { Box } from "@material-ui/core";

import NameFields from "../../ui/inputs/NameFields";
import EmailField from "../../ui/inputs/EmailField";
import { PrimaryButton } from "../../ui/Button";
import { SmallBody } from "../../ui/Typography";

import {
  CONTACT_FIELD_ID,
  CONTACT_MODAL_FORM_ID,
} from "../../../constants/forms";

import {
  selectContactRelationshipTranslations,
  selectLastSelectedContactData,
} from "../../../selectors/contacts";

import {
  featureContactsEmailEnabled,
  featureContactsRelationshipEnabled,
} from "../../../utilities/featureFlags";
import RelationshipField from "../../ui/inputs/RelationshipField";
import {
  CHILD_RELATIONSHIP,
  SPOUSE_OR_PARTNER_RELATIONSHIP,
} from "../../../constants/contacts";

const useTypedSelector: TypedUseSelectorHook<RootStateOrAny> = useSelector;

const ContactModal = ({
  invalid,
  handleSubmit,
  isLoading,
  translations,
  submitting,
}: InjectedFormProps & ContactModalProps) => {
  const contactData: ContactModalData = useTypedSelector(
    selectLastSelectedContactData,
  );

  const hasRoles = contactData?.hasRoles;
  const isAdult = !contactData?.isMinorChild;

  const relationshipTranslation: RelationshipTranslationsType = useSelector(
    selectContactRelationshipTranslations,
  );

  const isSpouseOrChild =
    contactData?.relationship === SPOUSE_OR_PARTNER_RELATIONSHIP ||
    contactData?.relationship === CHILD_RELATIONSHIP;

  return (
    <form onSubmit={handleSubmit}>
      <Box mt={1}>
        <NameFields
          useTheir
          fieldName={CONTACT_FIELD_ID}
          translations={translations}
          autoFocus={false}
          disableMiddleName={false}
        />
        {/* 
            Removing the ability for the user to change relationship type for spouse and child
            for info: https://www.pivotaltracker.com/story/show/188376423/comments/243162097
        */}
        {featureContactsRelationshipEnabled() && !isSpouseOrChild && (
          <RelationshipField
            contactData={contactData}
            fieldName={CONTACT_FIELD_ID}
            translations={{
              global: translations,
              relationship: relationshipTranslation,
            }}
          />
        )}
        {featureContactsEmailEnabled() && isAdult && (
          <EmailField
            useTheir
            autoFocus={false}
            translations={translations}
            optional
            fieldName={CONTACT_FIELD_ID}
            infoText
          />
        )}
        {hasRoles && (
          <Box mb={2}>
            <SmallBody>{translations.rolesInfo}</SmallBody>
          </Box>
        )}
        <PrimaryButton
          fullWidth
          isLoading={isLoading}
          text={translations.button.submit}
          opacity={invalid ? 0.5 : 1}
          isDisabled={submitting}
        />
      </Box>
    </form>
  );
};

export default reduxForm<{}, ContactModalProps>({
  form: CONTACT_MODAL_FORM_ID,
})(ContactModal);
