import React, { useEffect } from "react";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";

import { Box, Grid, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import { PageLayoutWithPrevious } from "../../components/ui/PageLayout";
import { TextButton } from "../../components/ui/Button";
import {
  Body,
  H1,
  SmallTextLink,
  TextLink,
} from "../../components/ui/Typography";
import { fetchPlans } from "../../actions/plans";

import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { PLANS_PATH } from "../../constants/routes";
import { RECOMMENDATION_FORM_ID } from "../../constants/forms";
import { essentialsPlanGuidanceProps } from "../../selectors/essentials-plan-guidance";
import { LegalEssentialsPlanCard } from "../../components/plans/PlanCard";
import EssentialsPreviewImage from "../../components/ui/images/EssentialsPreviewImage";

const EssentialsPlanGuidancePage = ({
  fetchPlans,
  goBack,
  hasLoaded,
  translations,
  price,
  strikeThroughPrice,
  nextSectionPath,
  showExclusiveOffer,
  isPremiumPlabAvailable,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    fetchPlans(RECOMMENDATION_FORM_ID);
  }, [fetchPlans]);

  if (!hasLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <PageLayoutWithPrevious>
      <Box mx={2}>
        <TextButton
          text={translations.button.previous}
          onClick={goBack}
          displayArrowLeft
        />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Grid item xl={8}>
            <H1>{translations.pageTitle}</H1>
            <Body align="center">{translations.description}</Body>
            {isPremiumPlabAvailable && (
              <Box align="center">
                <TextLink text={translations.upgradeLinkText} to={PLANS_PATH} />
              </Box>
            )}
          </Grid>
        </Box>

        <Box
          display="flex"
          flexDirection={isDesktop ? "row" : "column-reverse"}
          justifyContent="center"
        >
          <Box
            mt={isDesktop ? 1.5 : 2.5}
            width={isDesktop ? "700px" : "100%"}
            textAlign="center"
          >
            <EssentialsPreviewImage />
          </Box>
          <Box mt={isDesktop ? 0 : 3} ml={isDesktop ? 8.5 : 0}>
            <LegalEssentialsPlanCard
              price={price}
              strikeThroughPrice={strikeThroughPrice}
              noMaxWidth={!isDesktop}
              showExclusiveOffer={showExclusiveOffer}
              noCta
              showCheckoutButton
            />
            <Box mt={1.5} textAlign="center">
              <Hidden mdDown>
                {/* Desktop */}
                <SmallTextLink
                  className="qa-continue"
                  to={nextSectionPath}
                  text={translations.skipButton}
                />
              </Hidden>
              <Hidden lgUp>
                {/* Tablet */}
                <TextLink
                  className="qa-continue"
                  to={nextSectionPath}
                  text={translations.skipButton}
                />
              </Hidden>
            </Box>
          </Box>
        </Box>
      </Box>
    </PageLayoutWithPrevious>
  );
};

export default connect(essentialsPlanGuidanceProps, {
  fetchPlans,
  goBack,
})(EssentialsPlanGuidancePage);
