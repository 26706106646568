import R from "ramda";

import { UPDATE_STATUS_TYPE } from "../actions/status";
import {
  NUM_CHILDREN_FIELD_ID,
  NUM_PETS_FIELD_ID,
  CHILDREN_FIELD_ID,
  CHILDREN_GUARDIANS_FIELD_ID,
  PETS_FIELD_ID,
  PET_GUARDIANS_FIELD_ID,
  PET_TRUST_AMOUNT_FIELD_ID,
  FIRST_NAME_FIELD_ID,
  MIDDLE_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
  GENDER_FIELD_ID,
  EMAIL_FIELD_ID,
  CONTACT_FIELD_ID,
} from "../constants/forms";

const DEFAULT_STATE = {
  marital: "",
  spouse: {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
  },
  children: [],
  guardians: [],
  pets: [],
  petGuardians: [],
  petsVisited: false,
  hasMinorChildren: false,
  numPets: "",
  numChildren: "",
};

const yourFamily = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return {
        ...state,
        marital: R.pathOr(R.prop("marital", DEFAULT_STATE), [
          "user",
          "marital",
        ])(payload),
        needsSpouse: R.pathOr(R.prop("needsSpouse", DEFAULT_STATE), [
          "user",
          "needsSpouse",
        ])(payload),
        needsChildren: R.pathOr(R.prop("needsChildren", DEFAULT_STATE), [
          "user",
          "needsChildren",
        ])(payload),
        needsPets: R.pathOr(R.prop("needsPets", DEFAULT_STATE), [
          "user",
          "needsPets",
        ])(payload),
        petsVisited: R.propOr(false, "petsVisited")(payload),
        needsPetGuardian: R.pathOr(R.prop("needsPetGuardian", DEFAULT_STATE), [
          "user",
          "needsPetGuardian",
        ])(payload),
        hasMinorChildren: R.pathOr(R.prop("hasMinorChildren", DEFAULT_STATE), [
          "user",
          "hasMinorChildren",
        ])(payload),
        spouse: {
          [FIRST_NAME_FIELD_ID]: R.propOr("", [FIRST_NAME_FIELD_ID])(
            payload.spouse,
          ),
          [MIDDLE_NAME_FIELD_ID]: R.propOr("", [MIDDLE_NAME_FIELD_ID])(
            payload.spouse,
          ),
          [LAST_NAME_FIELD_ID]: R.propOr("", [LAST_NAME_FIELD_ID])(
            payload.spouse,
          ),
          [GENDER_FIELD_ID]: R.propOr("", [GENDER_FIELD_ID])(payload.spouse),
          [EMAIL_FIELD_ID]: R.pathOr("", [CONTACT_FIELD_ID, EMAIL_FIELD_ID])(
            payload.spouse,
          ),
        },
        [NUM_CHILDREN_FIELD_ID]: R.pathOr(
          R.prop(NUM_CHILDREN_FIELD_ID, DEFAULT_STATE),
          ["user", "numChildren"],
        )(payload),
        [NUM_PETS_FIELD_ID]: R.pathOr(
          R.prop(NUM_PETS_FIELD_ID, DEFAULT_STATE),
          ["user", "numPets"],
        )(payload),
        [CHILDREN_FIELD_ID]: R.propOr(
          [],
          CHILDREN_FIELD_ID,
        )(payload).sort((a, b) => a.id - b.id),
        [CHILDREN_GUARDIANS_FIELD_ID]: R.propOr(
          [],
          CHILDREN_GUARDIANS_FIELD_ID,
        )(payload).sort((a, b) => a.id - b.id),
        [PETS_FIELD_ID]: R.propOr(
          [],
          PETS_FIELD_ID,
        )(payload).sort((a, b) => a.id - b.id),
        [PET_GUARDIANS_FIELD_ID]: R.propOr(
          [],
          PET_GUARDIANS_FIELD_ID,
        )(payload).sort((a, b) => a.id - b.id),
        [PET_TRUST_AMOUNT_FIELD_ID]: R.propOr(
          "",
          PET_TRUST_AMOUNT_FIELD_ID,
        )(payload),
      };
    default:
      return state;
  }
};

export default yourFamily;
