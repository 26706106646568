import dayjs from "dayjs";

export const formatLocaleDateString = (
  date = new Date(),
  isFrench = false,
  options = {},
) => {
  const locale = isFrench ? "fr-CA" : "en-CA";
  const dateLocale = date.toLocaleDateString(locale, options);
  return dateLocale.replace(" ", "\u00a0");
};

export const getFirstDayOfNextMonth = (month, year) => {
  // javascript month date is zero indexed
  // [0: January, 1: February, 2: March ... 11: December]
  // that means, if we pass month as the number 3 we are
  // actually getting the first day of April here
  return new Date(Number(year), Number(month), 1);
};

export const addDaysToDate = (date = new Date(), days = 1) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
};

export const isAgeOfMajority = (birthdate, ageOfMajority) => {
  const today = dayjs(new Date());
  const dob = dayjs(new Date(birthdate));
  const age = today.diff(dob, "y");

  return age > ageOfMajority;
};

export const DOBDefaultDate = new Date(1975, 5, 15);
