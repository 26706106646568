export const openModalContact = (
  contact: ContactModalData,
  modalKey: string,
) => ({
  type: OPEN_MODAL_CONTACT_TYPE,
  payload: { contact, modalKey },
});

export const openModalContactForContactable = (
  contact: ContactModalData,
  modalKey: string,
  contactableId: number,
  isSecondaryContact: boolean = false,
  formId?: string,
  fieldId?: string,
  fieldIndex?: number,
) => ({
  type: OPEN_MODAL_CONTACT_TYPE,
  payload: {
    contact,
    modalKey,
    contactableId,
    isSecondaryContact,
    formId,
    fieldId,
    fieldIndex,
  },
});

export const contactUpdatedSuccessfully = (contact: Contact) => ({
  type: UPDATE_CONTACT_SUCCESSFULLY_TYPE,
  payload: { contact },
});

export const selectContactFromDropdown = (
  contactId: number,
  contactableId: number,
  contactableModel: ContactableModel,
  isSecondaryContact: boolean = false,
) => ({
  type: SELECT_CONTACT_FROM_DROPDOWN_TYPE,
  payload: {
    contactId,
    contactableId,
    contactableModel,
    isSecondaryContact,
  },
});

export const removeSecondaryContact = (
  contactId: number,
  contactableId: number,
  contactableModel: ContactableModel,
) => ({
  type: REMOVE_SECONDARY_CONTACT_TYPE,
  payload: { contactId, contactableId, contactableModel },
});

export const notifyContact = ({
  email,
  id,
  firstName,
  lastName,
  roles,
}: NotifyContactPayload) => ({
  type: NOTIFY_CONTACT_TYPE,
  payload: {
    email,
    id,
    firstName,
    lastName,
    roles,
  },
});

export const updateLastSelectedContact = (contact: Contact) => ({
  type: UPDATE_LAST_SELECTED_CONTACT_TYPE,
  payload: { contact },
});

export const storeContactableData = (data: object) => ({
  type: STORE_CONTACTABLE_DATA_TYPE,
  payload: { data },
});

export const OPEN_MODAL_CONTACT_TYPE = "OPEN_MODAL_CONTACT";
export const UPDATE_CONTACT_SUCCESSFULLY_TYPE = "UPDATE_CONTACT_SUCCESSFULLY";
export const NOTIFY_CONTACT_TYPE = "NOTIFY_CONTACT";
export const UPDATE_LAST_SELECTED_CONTACT_TYPE = "UPDATE_LAST_SELECTED_CONTACT";
export const SELECT_CONTACT_FROM_DROPDOWN_TYPE = "SELECT_CONTACT_FROM_DROPDOWN";
export const REMOVE_SECONDARY_CONTACT_TYPE = "REMOVE_SECONDARY_CONTACT";
export const STORE_CONTACTABLE_DATA_TYPE = "STORE_CONTACTABLE_DATA";
