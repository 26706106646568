import React from "react";
import SvgIcon from "./SvgIcon";

const DigitalExecutionAddon = () => {
  return (
    <SvgIcon
      width="44"
      height="48"
      viewBox="0 0 44 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.3304 13.4055C37.9679 12.65 38.9061 12.2141 39.8946 12.2141C43.3068 12.2141 44.5404 16.7154 41.6049 18.455L41.0958 18.7568L34.3784 22.6929C33.338 23.3025 32.3464 23.9917 31.4125 24.7545L28.893 26.8122C27.0017 28.357 24.7145 29.3395 22.292 29.6478L20.8407 29.8325C20.7114 29.849 20.5805 29.849 20.4512 29.8325C18.9284 29.6387 18.5927 27.5816 19.9749 26.9137L28.7947 22.6521C29.2961 22.4098 29.7476 22.0755 30.1257 21.6665L32.8155 18.7568L37.3304 13.4055Z"
        fill="#2F455F"
      />
      <path
        d="M35.049 25.9999L25.9969 19.9979L13.2166 20.012L4.19458 26.0341L4.20807 31.4304L4.19458 31.4395L4.21584 39.9418L13.2679 45.9438L26.0482 45.9297L35.0703 39.9076L35.0568 34.5112L35.0703 34.5022L35.049 25.9999Z"
        fill="#2F455F"
      />
      <path
        d="M22.8655 28.2811L31.9937 34.043L32.0151 42.2052L22.9173 47.9864L10.0296 48L0.901445 42.238L0.880005 34.0758L9.97783 28.2946L22.8655 28.2811Z"
        fill="#FFDC1E"
      />
      <path
        d="M22.8655 26.7243L31.9937 32.4863L32.0151 40.6485L22.9173 46.4296L10.0296 46.4432L0.901445 40.6813L0.880005 32.519L9.97783 26.7379L22.8655 26.7243Z"
        fill="#E39F3A"
      />
      <path
        d="M31.9943 32.6919L32.0152 40.6485L22.9341 46.419V32.6919H31.9943Z"
        fill="#BC7F35"
      />
      <path
        d="M22.8655 21.7946L31.9937 27.5566L32.0151 35.7188L22.9173 41.5L10.0296 41.5135L0.901445 35.7516L0.880005 27.5894L9.97783 21.8082L22.8655 21.7946Z"
        fill="#71ADEE"
      />
      <path
        d="M18.5869 35.8054L21.6296 33.9858L21.6367 31.4083L18.6041 29.5827L14.3082 29.5784L11.2655 31.3979L11.2584 33.9755L14.291 35.8011L18.5869 35.8054Z"
        fill="#AAD2F6"
      />
      <path
        d="M18.5869 34.7676L21.6296 32.948L21.6367 30.3704L18.6041 28.5448L14.3082 28.5405L11.2655 30.3601L11.2584 32.9376L14.291 34.7633L18.5869 34.7676Z"
        fill="#E2F6FF"
      />
      <path
        d="M9.46209 8.83598C8.39256 4.32629 11.8128 0 16.4476 0C21.0824 0 24.5026 4.3263 23.4331 8.83599L22.244 13.85C22.1212 14.3676 21.9619 14.8758 21.7673 15.3708L20.2711 19.1763C19.6848 20.6675 19.5869 22.3062 19.9913 23.8566L20.7995 26.9541C21.4449 29.4281 19.9008 31.9419 17.4022 32.4845C16.7731 32.6212 16.1221 32.6212 15.493 32.4845C12.9944 31.9419 11.4503 29.4281 12.0957 26.9541L12.9039 23.8566C13.3083 22.3062 13.2104 20.6675 12.6241 19.1763L11.1279 15.3708C10.9333 14.8758 10.774 14.3676 10.6512 13.85L9.46209 8.83598Z"
        fill="#0064DC"
      />
      <path
        d="M14.2568 32.019C14.634 32.229 15.0484 32.388 15.493 32.4845C16.122 32.6212 16.7731 32.6212 17.4022 32.4845C19.9008 31.9419 21.4449 29.4281 20.7994 26.9541L19.9913 23.8566C19.5868 22.3062 19.6848 20.6675 20.2711 19.1763L21.7673 15.3708C21.9619 14.8758 22.1212 14.3676 22.244 13.85L23.4331 8.83599C24.5026 4.3263 21.0824 0 16.4476 0C16.3947 0 16.342 0.000562812 16.2895 0.00168229C19.4531 1.28219 21.4269 4.74202 20.5791 8.3171L19.3899 13.3312C19.2672 13.8487 19.1079 14.3569 18.9132 14.8519L17.417 18.6574C16.8307 20.1486 16.7328 21.7874 17.1373 23.3378L17.9454 26.4353C18.5908 28.9093 17.0467 31.423 14.5482 31.9657C14.4515 31.9867 14.3544 32.0044 14.2568 32.019Z"
        fill="#004EB6"
      />
    </SvgIcon>
  );
};
export default DigitalExecutionAddon;
