const CATEGORY = "Online Wills";
const BRAND = "Willful";

export const sendEvent = ({ category, action, label }) => ({
  type: ANALYTICS_SEND_EVENT_TYPE,
  payload: {
    category,
    action,
    label,
  },
});

export const addProduct = ({ name, price, province, quantity, coupon }) => ({
  type: ANALYTICS_ADD_PRODUCT_TYPE,
  payload: {
    name /* either this or the id field has to be filled */,
    price: `${price}` /* (optional) */,
    id: `${name}-${province}`,
    brand: BRAND /* (optional) */,
    category: CATEGORY /* (optional) */,
    variant: province,
    quantity /* (optional) */,
    coupon /* (optional) */,
    // position: /* (optional) */,
  },
});

export const addPromo = ({ promoCode }) => ({
  type: ANALYTICS_ADD_PROMO_TYPE,
  payload: {
    promoCode,
  },
});

export const addPromoComplete = () => ({
  type: ANALYTICS_ADD_PROMO_COMPLETE_TYPE,
});

export const setAction = ({ actionName, ...rest }) => ({
  type: ANALYTICS_SET_ACTION_TYPE,
  payload: {
    actionName,
    ...rest,
  },
});

export const viewCheckoutAnalytics = () => ({
  type: ANALYTICS_VIEW_CHECKOUT_TYPE,
});

export const viewPlansAnalytics = () => ({
  type: ANALYTICS_VIEW_PLANS_TYPE,
});

export const referralVisitAnalytics = ({ referral }) => ({
  type: ANALYTICS_REFERRAL_VISIT_TYPE,
  payload: {
    referral,
  },
});

export const checkoutPurchaseAnalytics = ({
  transactionId,
  userId,
  revenue,
  quantity,
  coupon,
  planName,
  planPrice,
  provinceCode,
  totalTax,
}) => ({
  type: ANALYTICS_CHECKOUT_PURCHASE_TYPE,
  payload: {
    transactionId,
    userId,
    revenue,
    quantity,
    coupon,
    planName,
    planPrice,
    provinceCode,
    tax: totalTax,
  },
});

export const addPromoCodeAnalytics = ({ promoCode }) => ({
  type: ANALYTICS_CHECKOUT_PROMO_APPLIED_TYPE,
  payload: {
    promoCode,
  },
});

export const selectedPlanAnalytics = ({
  plan,
  price,
  quantity,
  province,
  previousPlan,
  previousQuantity,
}) => ({
  type: ANALYTICS_SELECTED_PLAN_TYPE,
  payload: {
    plan,
    price,
    quantity,
    province,
    previousPlan,
    previousQuantity,
  },
});

export const viewSignupAnalytics = () => ({
  type: ANALYTICS_VIEW_SIGNUP_TYPE,
  payload: {},
});

export const signupAnalytics = ({ email, firstName, lastName, userId }) => ({
  type: ANALYTICS_SIGNUP_TYPE,
  payload: { email, firstName, lastName, userId },
});

export const loginAnalytics = ({ email, userId }) => ({
  type: ANALYTICS_LOGIN_TYPE,
  payload: { email, userId },
});

export const logoutAnalytics = (userId) => ({
  type: ANALYTICS_LOGOUT_TYPE,
  payload: { userId },
});

export const lockedProvinceLeadAnalytics = ({ email, province }) => ({
  type: ANALYTICS_LOCKED_PROVINCE_NOTIFY_TYPE,
  payload: {
    email,
    province,
  },
});

export const analyticsClickExternalLink = (url) => ({
  type: ANALYTICS_CLICKED_EXTERNAL_LINK_TYPE,
  payload: { url },
});

export const analyticsClickedNavLink = (menu, item) => ({
  type: ANALYTICS_CLICKED_NAV_LINK_TYPE,
  payload: { menu, item },
});

export const analyticsOpenedChat = () => ({
  type: ANALYTICS_OPENED_CHAT_TYPE,
});

export const analyticsDownloadNotarizationGuide = () => ({
  type: ANALYTICS_DOWNLOAD_NOTARIZATION_GUIDE_TYPE,
});

export const userPlanQuantityAnalytics = (quantity) => ({
  type: ANALYTICS_SET_USER_PLAN_QUANTITY_TYPE,
  payload: {
    quantity,
  },
});

export const isInvitedUserAnalytics = (isInvitedUser) => ({
  type: ANALYTICS_SET_USER_INVITED_TYPE,
  payload: {
    isInvitedUser,
  },
});

export const userChangedLanguageAnalytics = (lang) => ({
  type: ANALYTICS_USER_CHANGED_LANGUAGE_TYPE,
  payload: {
    lang,
  },
});

export const analyticsViewPage = (location, isLoggedIn, device) => ({
  type: ANALYTICS_VIEW_PAGE_TYPE,
  payload: {
    location,
    isLoggedIn,
    device,
  },
});

export const userChangedUnsupportedLanguageAnalytics = (lang) => ({
  type: ANALYTICS_USER_CHANGED_UNSUPPORTED_LANGUAGE_TYPE,
  payload: {
    lang,
  },
});

export const taplyticsIdentifyAnalytics = ({
  email,
  userId,
  provinceCode,
}) => ({
  type: ANALYTICS_TAPLYTICS_IDENTIFY_TYPE,
  payload: { email, userId, provinceCode },
});

export const hotjarIdentifyAnalytics = ({
  email,
  userId,
  isInvitedUser,
  isPaidUser,
  isSessionPurchaser,
}) => ({
  type: ANALYTICS_HOTJAR_IDENTIFY_TYPE,
  payload: { email, userId, isInvitedUser, isPaidUser, isSessionPurchaser },
});

export const analyticsClickedSkipPayLater = () => ({
  type: ANALYTICS_CLICKED_SKIP_PAY_LATER_TYPE,
});

export const analyticsClickedEdit = (section, page) => ({
  type: ANALYTICS_CLICKED_EDIT_TYPE,
  payload: { section, page },
});

export const analyticsHandleInitialize = () => ({
  type: ANALYTICS_HANDLE_INITIALIZE_TYPE,
});

export const analyticsResetUser = () => ({
  type: ANALYTICS_RESET_USER_TYPE,
});

export const analyticsSetSessionId = (sessionId) => ({
  type: ANALYTICS_SET_SESSION_ID_TYPE,
  payload: { sessionId },
});

export const analyticsClickedCheckoutNow = () => ({
  type: ANALYTICS_CLICKED_CHECKOUT_NOW_TYPE,
});

export const initializeGA4Parameters = (parameters) => ({
  type: INITIALIZE_GA4_PARAMETERS_TYPE,
  payload: parameters,
});

export const analyticsInterestedInBNPL = () => ({
  type: ANALYTICS_INTERESTED_IN_BNPL,
});

export const analyticsOpenedModal = ({ key, url }) => ({
  type: ANALYTICS_OPENED_MODAL,
  payload: { key, url },
});

export const analyticsClosedModal = ({ key, url }) => ({
  type: ANALYTICS_CLOSED_MODAL,
  payload: { key, url },
});

export const analyticsOpenedCartWidget = () => ({
  type: ANALYTICS_OPENED_CART_WIDGET,
});

export const analytics401Error = ({ type }) => ({
  type: ANALYTICS_401_ERROR,
  payload: { type },
});

export const analyticsGoogleSSOAttempt = ({ type, status }) => ({
  type: ANALYTICS_GOOGLE_SSO_ATTEMPT,
  payload: { type, status },
});

export const analyticsCopiedReferralLink = () => ({
  type: ANALYTICS_COPIED_REFERRAL_LINK,
});

export const analyticsManuallyCopiedReferralLink = () => ({
  type: ANALYTICS_MANUALLY_COPIED_REFERRAL_LINK,
});

export const analyticsClickedAddonButton = ({ name, purchased }) => ({
  type: ANALYTICS_CLICKED_ADDON_BUTTON,
  payload: { name, purchased },
});

export const ANALYTICS_VIEW_PLANS_TYPE = "ANALYTICS_VIEW_PLANS";
export const ANALYTICS_SELECTED_PLAN_TYPE = "ANALYTICS_SELECTED_PLAN";
export const ANALYTICS_CHECKOUT_PURCHASE_TYPE = "ANALYTICS_CHECKOUT_PURCHASE";
export const ANALYTICS_VIEW_CHECKOUT_TYPE = "ANALYTICS_VIEW_CHECKOUT";
export const ANALYTICS_CHECKOUT_PROMO_APPLIED_TYPE =
  "ANALYTICS_CHECKOUT_PROMO_APPLIED";
export const ANALYTICS_CLICKED_EXTERNAL_LINK_TYPE =
  "ANALYTICS_CLICKED_EXTERNAL_LINK";
export const ANALYTICS_CLICKED_NAV_LINK_TYPE = "ANALYTICS_CLICKED_NAV_LINK";
export const ANALYTICS_OPENED_CHAT_TYPE = "ANALYTICS_OPENED_CHAT";
export const ANALYTICS_DOWNLOAD_NOTARIZATION_GUIDE_TYPE =
  "ANALYTICS_DOWNLOAD_NOTARIZATION_GUIDE";

export const ANALYTICS_ADD_PRODUCT_TYPE = "ANALYTICS_ADD_PRODUCT";
export const ANALYTICS_SET_ACTION_TYPE = "ANALYTICS_SET_ACTION";
export const ANALYTICS_ADD_PROMO_TYPE = "ANALYTICS_ADD_PROMO";
export const ANALYTICS_SEND_EVENT_TYPE = "ANALYTICS_SEND_EVENT";
export const ANALYTICS_TAPLYTICS_IDENTIFY_TYPE = "ANALYTICS_TAPLYTICS_IDENTIFY";
export const ANALYTICS_ADD_PROMO_COMPLETE_TYPE = "ANALYTICS_ADD_PROMO_COMPLETE";

// Auth
export const ANALYTICS_LOCKED_PROVINCE_NOTIFY_TYPE =
  "ANALYTICS_LOCKED_PROVINCE_NOTIFY";
export const ANALYTICS_VIEW_SIGNUP_TYPE = "ANALYTICS_VIEW_SIGNUP";
export const ANALYTICS_SIGNUP_TYPE = "ANALYTICS_SIGNUP";
export const ANALYTICS_LOGIN_TYPE = "ANALYTICS_LOGIN";
export const ANALYTICS_LOGOUT_TYPE = "ANALYTICS_LOGOUT";
export const ANALYTICS_REFERRAL_VISIT_TYPE = "ANALYTICS_REFERRAL_VISIT_TYPE";
export const ANALYTICS_SET_USER_PLAN_QUANTITY_TYPE =
  "ANALYTICS_SET_USER_PLAN_QUANTITY";
export const ANALYTICS_SET_USER_INVITED_TYPE = "ANALYTICS_SET_USER_INVITED";
export const ANALYTICS_USER_CHANGED_LANGUAGE_TYPE =
  "ANALYTICS_SET_USER_LANGUAGE";
export const ANALYTICS_USER_CHANGED_UNSUPPORTED_LANGUAGE_TYPE =
  "ANALYTICS_USER_CHANGED_UNSUPPORTED_LANGUAGE_TYPE";
export const ANALYTICS_VIEW_PAGE_TYPE = "ANALYTICS_VIEW_PAGE";
export const ANALYTICS_CLICKED_SKIP_PAY_LATER_TYPE =
  "ANALYTICS_CLICKED_SKIP_PAY_LATER";
export const ANALYTICS_CLICKED_EDIT_TYPE = "ANALYTICS_CLICKED_EDIT";
export const ANALYTICS_HANDLE_INITIALIZE_TYPE =
  "ANALYTICS_HANDLE_INITIALIZE_TYPE";
export const ANALYTICS_RESET_USER_TYPE = "ANALYTICS_RESET_USER";
export const ANALYTICS_SET_SESSION_ID_TYPE = "ANALYTICS_SET_SESSION_ID";
export const ANALYTICS_PREVIEWED_APPOINTEE_EMAIL_PREVIEW_TYPE =
  "ANALYTICS_PREVIEWED_APPOINTEE_EMAIL_PREVIEW";
export const ANALYTICS_CLICKED_CHECKOUT_NOW_TYPE =
  "ANALYTICS_CLICKED_CHECKOUT_NOW";
export const INITIALIZE_GA4_PARAMETERS_TYPE = "INITIALIZE_GA4_PARAMETERS";
export const ANALYTICS_INTERESTED_IN_BNPL = "ANALYTICS_INTERESTED_IN_BNPL";
export const ANALYTICS_HOTJAR_IDENTIFY_TYPE = "ANALYTICS_HOTJAR_IDENTIFY";
export const ANALYTICS_OPENED_MODAL = "ANALYTICS_OPENED_MODAL";
export const ANALYTICS_CLOSED_MODAL = "ANALYTICS_CLOSED_MODAL";
export const ANALYTICS_OPENED_CART_WIDGET = "ANALYTICS_OPENED_CART_WIDGET";
export const ANALYTICS_401_ERROR = "ANALYTICS_401_ERROR";
export const ANALYTICS_GOOGLE_SSO_ATTEMPT = "ANALYTICS_GOOGLE_SSO_ATTEMPT";
export const ANALYTICS_COPIED_REFERRAL_LINK = "ANALYTICS_COPIED_REFERRAL_LINK";
export const ANALYTICS_MANUALLY_COPIED_REFERRAL_LINK =
  "ANALYTICS_MANUALLY_COPIED_REFERRAL_LINK";
export const ANALYTICS_CLICKED_ADDON_BUTTON = "ANALYTICS_CLICKED_ADDON_BUTTON";
