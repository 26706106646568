import R from "ramda";
import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  actionTypes as reduxFormActionTypes,
  change,
  clearAsyncError,
  getFormValues,
  initialize,
  stopSubmit,
  touch,
  untouch,
} from "redux-form";

import {
  ADD_INHERITANCE_AGE_MILESTONE_TYPE,
  personBackupRemovedSuccesfully,
  BACKUPS_NEXT_PAGE_TYPE,
  DISTRIBUTE_ALLOCATIONS_EVENLY_TYPE,
  FETCH_ALLOCATIONS_TYPE,
  FETCH_BACKUP_TYPE,
  FETCH_INHERITANCE_TYPE,
  forcePieChartAnimation,
  REMOVE_ALLOCATION_TYPE,
  REMOVE_PERSON_BACKUP_ON_PROGRESS_TYPE,
  REMOVE_BACKUP_TYPE,
  REMOVE_INHERITANCE_AGE_MILESTONE_TYPE,
  UPDATE_ALLOCATION_CARD_TYPE,
  UPDATE_ALLOCATIONS_TYPE,
  UPDATE_BACKUP_TYPE,
  UPDATE_INHERITANCE_TYPE,
  UPDATE_PIE_CHART_DATA_TYPE,
  UPDATE_PREDECEASE_TYPE,
  UPDATE_PRIMARY_BENEFICIARY_TYPE,
  updateAgeMilestoneVisibility,
  updateLastAllocationsValidState,
  UPDATE_CHARITY_BACKUP_ON_PROGRESS_TYPE,
  REMOVE_CHARITY_BACKUP_ON_PROGRESS_TYPE,
  charityBackupRemovedSuccesfully,
  charityBackupUpdatedSuccesfully,
  OPEN_MODAL_CUSTOM_CHARITY_TYPE,
} from "../actions/allocations";
import { updateCharities } from "../actions/charities";
import { fetchSuccess } from "../actions/requests";
import { submitFormRequest } from "./forms";
import { fetchApiData } from "./requests";
import { selectHasLoaded } from "../selectors/requests";
import {
  selectCharityNameById,
  selectHasCharities,
} from "../selectors/donations";
import {
  selectAllocationsForm,
  selectAllocationsFormTotalPercentage,
  selectAllocationsFormValidValues,
  selectAllocationsFormValidValuesForContacts,
  selectAllocationsTranslations,
  selectFormAllocations,
  selectInheritanceAgeMilestoneVisibilityState,
  selectInheritanceForm,
  selectNewPieChartAnimationKey,
} from "../selectors/allocations";
import { selectBackupForm, selectFormBackups } from "../selectors/backup";
import {
  getAllocations,
  getBackupsNextPage,
  removeBackupEndpoint,
  submitAllocations,
  submitBackups,
  submitInheritance,
  submitPredecease,
  submitPrimaryBeneficiary,
} from "../api/allocations";
import {
  ADD_PERSON_BENEFICIARY_FIELD_ID,
  ALLOCATIONS_FIELD_ID,
  ALLOCATIONS_FORM_ID,
  BACKUPS_FIELD_ID,
  BACKUPS_FORM_ID,
  BUSINESS_NUMBER_FIELD_ID,
  CHARITY_ALLOCATION,
  CHARITY_BACKUP,
  CHARITY_FORM_ID,
  CHARITY_ID_FIELD_ID,
  CUSTOM_CHARITY_FORM_ID,
  DONATIONS_CUSTOM_CHARITY,
  DONATIONS_LIST_CHARITY,
  INHERITANCE_FORM_ID,
  PERSON_ALLOCATION,
  PERSON_ALLOCATION_FORM_ID,
  PERSON_BACKUP,
  PREDECEASE_FORM_ID,
  PRIMARY_BENEFICIARY_FORM_ID,
  REGISTERED_NAME_FIELD_ID,
} from "../constants/forms";
import {
  distributeAmountsEvenly,
  removeAllocationFromList,
} from "../utilities/allocations";
import {
  MODAL_ADD_CUSTOM_BENEFICIARY_CHARITY,
  MODAL_EDIT_BENEFICIARY_CHARITY,
  MODAL_EDIT_BENEFICIARY_CUSTOM_CHARITY,
  MODAL_EDIT_BENEFICIARY_PERSON,
  MODAL_REMOVE_BENEFICIARY,
} from "../constants/modal";
import { closeModal, displayModal } from "../actions/modal";
import {
  getAllocationsErrorMessage,
  percentageValidation,
} from "../components/forms/utils/allocations-form-validations";
import { getFormData } from "../actions/forms";
import { featureAssociateContactsEnabled } from "../utilities/featureFlags";
import { closeToast, displayToast } from "../actions/toast";
import {
  REMOVE_CHARITY_BACKUP_SUCCESS,
  REMOVE_PERSON_BACKUP_SUCCESS,
  UPDATE_CHARITY_BACKUP_SUCCESS,
} from "../constants/toasts";
import { deepTransformKeysToCamelCase } from "../utilities/helpers";

function* fetchAllocations() {
  const hasLoaded = yield select(selectHasLoaded);
  const hasCharities = yield select(selectHasCharities);

  if (!hasLoaded || !hasCharities) {
    const { charities } = yield call(fetchApiData, {
      apiCall: getAllocations,
      formId: ALLOCATIONS_FORM_ID,
    });
    yield put(updateCharities(charities));
  }

  const allocationsFormData = yield select(selectAllocationsForm);
  const { allocations } = allocationsFormData;
  yield put(initialize(ALLOCATIONS_FORM_ID, allocationsFormData));
  yield put(updateLastAllocationsValidState(allocations));
  yield put(fetchSuccess(ALLOCATIONS_FORM_ID));
}

function* updateAllocations() {
  const totalPercentage = yield select(selectAllocationsFormTotalPercentage);
  if (totalPercentage === 100) {
    // if percentage totals to a 100, remove async validation errors
    yield put(clearAsyncError(ALLOCATIONS_FORM_ID, ALLOCATIONS_FIELD_ID));

    // submit request to the backend
    const { status } = yield call(submitFormRequest, {
      apiCall: submitAllocations,
      formId: ALLOCATIONS_FORM_ID,
    });
    if (status !== 200) {
      yield put(stopSubmit(ALLOCATIONS_FORM_ID));
      return null;
    }
    return yield put(getFormData(ALLOCATIONS_FORM_ID));
  }
  // forcibly show validation errors if percentage is different than 100
  const allocations = yield select(selectAllocationsFormValidValues);
  const percentageLeftover = percentageValidation(allocations);
  return yield triggerAllocationsAsyncValidation(percentageLeftover);
}

function* updateAllocationsEvenly({ payload }) {
  const { allocations } = payload;
  if (allocations) {
    const evenAllocations = yield call(distributeAmountsEvenly, allocations);
    yield put(
      change(ALLOCATIONS_FORM_ID, ALLOCATIONS_FIELD_ID, evenAllocations),
    );
  } else {
    const formAllocations = yield select(selectFormAllocations);
    const evenAllocations = yield call(
      distributeAmountsEvenly,
      formAllocations,
    );
    yield put(
      change(ALLOCATIONS_FORM_ID, ALLOCATIONS_FIELD_ID, evenAllocations),
    );
  }
}

function* removeAllocation() {
  yield put(displayModal(MODAL_REMOVE_BENEFICIARY, true));
}

function* removeBackup({ payload }) {
  const { backup } = payload;
  if (!backup) return;
  const formBackups = yield select(selectFormBackups);
  const newBackups = removeAllocationFromList(backup, formBackups);

  yield put(change(BACKUPS_FORM_ID, BACKUPS_FIELD_ID, newBackups));
}

function* updatePrimaryBeneficiary() {
  yield call(submitFormRequest, {
    apiCall: submitPrimaryBeneficiary,
    formId: PRIMARY_BENEFICIARY_FORM_ID,
  });
}

function* updatePredecease() {
  yield call(submitFormRequest, {
    apiCall: submitPredecease,
    formId: PREDECEASE_FORM_ID,
  });
}

function* updateInheritance() {
  const rawFormValues = yield select(getFormValues(INHERITANCE_FORM_ID));
  const {
    ageMilestone,
    age1,
    age2,
    age3,
    percentage1,
    percentage2,
    percentage3,
  } = rawFormValues;

  const inheritanceAges = [];
  if (ageMilestone === "specific_ages") {
    if (age1) {
      inheritanceAges.push({ age: age1, percentage: percentage1 });
    }
    if (age2) {
      inheritanceAges.push({ age: age2, percentage: percentage2 });
    }
    if (age3) {
      inheritanceAges.push({ age: age3, percentage: percentage3 });
    }
  }

  const values = {
    ageMilestone,
    inheritanceAges,
  };

  yield call(submitFormRequest, {
    apiCall: submitInheritance,
    formId: INHERITANCE_FORM_ID,
    values,
  });
}

function* addInheritanceAgeMilestone() {
  const visibilityState = yield select(
    selectInheritanceAgeMilestoneVisibilityState,
  );
  // Only displays milestone3 if milestone2 is already visible
  const hasSeconddMilestone = visibilityState.displayMilestone2;

  const milestonesVisibility = {
    displayMilestone2: true,
    displayMilestone3: hasSeconddMilestone,
  };

  yield put(updateAgeMilestoneVisibility(milestonesVisibility));
}

function* clearInheritanceAgeMilestoneField(formId, ageField, percentageField) {
  yield put(change(formId, ageField, null));
  yield put(change(formId, percentageField, null));
  yield put(untouch(formId, ageField, ""));
  yield put(untouch(formId, percentageField, ""));
}

function* removeInheritanceAgeMilestone({ payload }) {
  const { milestoneIndex } = payload;
  if (!milestoneIndex) return;

  const formId = INHERITANCE_FORM_ID;
  const formValues = yield select(getFormValues(formId));

  const ageField = `age${milestoneIndex}`;
  const percentageField = `percentage${milestoneIndex}`;

  const age3 = R.propOr(null, "age3", formValues);
  const percentage3 = R.propOr(null, "percentage3", formValues);
  const visibilityState = yield select(
    selectInheritanceAgeMilestoneVisibilityState,
  );
  const hasThirdMilestone = visibilityState.displayMilestone3;

  // If a user has 3 milestones and removes the 2nd, the value of the 3rd should replace the 2nd
  if (milestoneIndex === 2 && hasThirdMilestone) {
    yield put(change(formId, ageField, age3));
    yield put(change(formId, percentageField, percentage3));
    yield call(
      clearInheritanceAgeMilestoneField,
      formId,
      "age3",
      "percentage3",
    );
  } else {
    yield call(
      clearInheritanceAgeMilestoneField,
      formId,
      ageField,
      percentageField,
    );
  }

  const milestonesVisibility = {
    displayMilestone2: hasThirdMilestone,
    displayMilestone3: false,
  };

  yield put(updateAgeMilestoneVisibility(milestonesVisibility));
}

function* fetchBackup() {
  const hasLoaded = yield select(selectHasLoaded);
  const hasCharities = yield select(selectHasCharities);

  if (!hasLoaded || !hasCharities) {
    const { charities } = yield call(fetchApiData, {
      apiCall: getAllocations,
      formId: ALLOCATIONS_FORM_ID,
    });

    yield put(updateCharities(charities));
  }
  const backupFormData = yield select(selectBackupForm);
  yield put(initialize(BACKUPS_FORM_ID, backupFormData));
  yield put(fetchSuccess(BACKUPS_FORM_ID));
}

function* updateBackup() {
  yield call(submitFormRequest, {
    apiCall: submitBackups,
    formId: BACKUPS_FORM_ID,
  });
}

export function* handleUpdatePersonBackupOnProgress(
  backupToUpdate,
  contactId = null,
) {
  yield put(closeToast());
  const response = yield call(submitFormRequest, {
    apiCall: submitBackups,
    formId: BACKUPS_FORM_ID,
    values: {
      ...R.mapObjIndexed(R.defaultTo(null), backupToUpdate),
      contactId,
      type: PERSON_BACKUP,
    },
  });
  const { status, backup } = response;
  if (status !== 200 || !backup) {
    yield put(stopSubmit(BACKUPS_FORM_ID));
    return null;
  }
  return backup.id;
}

export function* handleUpdateCharityBackupOnProgress({ payload }) {
  yield put(closeToast());
  const charityToUpdate = R.propOr(null, "backup")(payload);
  const charityId = R.propOr(null, "charityId")(payload);
  const isCustomInput = R.propOr(null, "isCustomInput")(payload);
  const entryType = isCustomInput
    ? DONATIONS_CUSTOM_CHARITY
    : DONATIONS_LIST_CHARITY;

  const response = yield call(submitFormRequest, {
    apiCall: submitBackups,
    formId: BACKUPS_FORM_ID,
    values: {
      ...charityToUpdate,
      charityId,
      entryType,
      type: CHARITY_BACKUP,
    },
  });

  const { status, backup } = response;
  if (status !== 200 || !backup) {
    yield put(stopSubmit(BACKUPS_FORM_ID));
    return null;
  }

  const formattedBackupData = deepTransformKeysToCamelCase(backup);
  const charityName = yield call(getCharityName, formattedBackupData);
  yield put(charityBackupUpdatedSuccesfully(charityName));
  yield call(fetchBackup);
  yield put(displayToast(UPDATE_CHARITY_BACKUP_SUCCESS));
  yield put(closeModal());
  return backup.id;
}

function* handleRemovePersonBackupOnProgress({ payload }) {
  yield put(closeToast());

  const backup = R.propOr(null, "backup")(payload);
  yield call(submitFormRequest, {
    apiCall: removeBackupEndpoint,
    values: {
      ...backup,
    },
  });

  const formattedBackupData = deepTransformKeysToCamelCase(backup?.contact);
  yield put(personBackupRemovedSuccesfully(formattedBackupData));
  yield put(displayToast(REMOVE_PERSON_BACKUP_SUCCESS));
}

function* handleRemoveCharityBackupOnProgress({ payload }) {
  yield put(closeToast());

  const backup = R.propOr(null, "backup")(payload);
  yield call(submitFormRequest, {
    apiCall: removeBackupEndpoint,
    values: {
      ...backup,
    },
  });

  const charityName = yield call(getCharityName, backup);
  yield put(charityBackupRemovedSuccesfully(charityName));
  yield put(displayToast(REMOVE_CHARITY_BACKUP_SUCCESS));
}

function* getCharityName(backup) {
  if (backup.registeredName) {
    return yield backup.registeredName;
  }
  return yield select(selectCharityNameById(backup.charityId));
}

function* handleBackupsNextPage() {
  return yield call(submitFormRequest, {
    apiCall: getBackupsNextPage,
  });
}

function* fetchInheritance() {
  const formId = INHERITANCE_FORM_ID;

  const hasLoaded = yield select(selectHasLoaded);
  if (!hasLoaded) {
    yield call(fetchApiData, {
      apiCall: getAllocations,
      formId,
    });
  }

  const formData = yield select(selectInheritanceForm);
  yield put(initialize(formId, formData));

  const { age2, age3 } = formData;
  const milestonesVisibility = {
    displayMilestone2: age2,
    displayMilestone3: age3,
  };
  yield put(updateAgeMilestoneVisibility(milestonesVisibility));

  yield put(fetchSuccess(formId));

  // this is to guarantee that percentage1 field will show
  // errors if the user removes milestone2 and milestone3
  if (age2 || age3) yield put(touch(formId, "percentage1"));
}

function* triggerAllocationsAsyncValidation(percentageLeftover) {
  const translations = yield select(selectAllocationsTranslations);
  const errorMessage = getAllocationsErrorMessage(
    percentageLeftover,
    translations,
  );
  yield delay(10); // delay will avoid inconsistencies where the form level error is not properly shown
  yield put(stopSubmit(ALLOCATIONS_FORM_ID, { _error: errorMessage }));
}

function* handleAllocationsFormChange({ meta }) {
  const associateContactsEnabled = featureAssociateContactsEnabled();
  const { form, field, isRemoving = false } = meta;

  // since it is a fieldArray the field can be "allocations" if it is
  // adding or removing allocations, or "allocation[index].amount"
  // if the user is changing the percentage amount
  const isAllocationsFieldArray =
    /allocations\[[0-9]+\].amount/i.test(field) ||
    field === ALLOCATIONS_FIELD_ID;

  if (form === ALLOCATIONS_FORM_ID && isAllocationsFieldArray) {
    const totalPercentage = yield select(selectAllocationsFormTotalPercentage);
    let allocations = [];
    if (associateContactsEnabled) {
      allocations = yield select(selectAllocationsFormValidValuesForContacts);
    } else {
      allocations = yield select(selectAllocationsFormValidValues);
    }
    const allocationsWithAmount = allocations.filter(
      (a) => a?.amount > 0 && a._destroy !== true,
    );
    const percentageLeftover = percentageValidation(allocationsWithAmount);

    if (
      totalPercentage === 100 ||
      (percentageLeftover && Math.abs(percentageLeftover) < 1)
    ) {
      yield put(updateLastAllocationsValidState(allocations));

      // after updating the last valid state, we will force the pie chart animation
      const key = yield select(selectNewPieChartAnimationKey);
      yield put(forcePieChartAnimation(key));

      if (totalPercentage !== 100) {
        // forcibly show validation errors if percentage is different than 100
        yield triggerAllocationsAsyncValidation(percentageLeftover);
      }
    }

    if (associateContactsEnabled) {
      if (isRemoving && percentageLeftover > 0) {
        // when removing an allocation, if there is a percentage leftover
        // we will add an unallocated allocation to the list
        yield put(
          updateLastAllocationsValidState([
            ...allocations,
            { amount: percentageLeftover, unallocated: true },
          ]),
        );

        // after updating the last valid state, we will force the pie chart animation
        const key = yield select(selectNewPieChartAnimationKey);
        yield put(forcePieChartAnimation(key));
      }
    }
  }
}

function* handleOpenModalCustomCharity() {
  yield put(closeToast());
  return yield put(displayModal(MODAL_ADD_CUSTOM_BENEFICIARY_CHARITY));
}

export function* watchFetchAllocations() {
  yield takeEvery(FETCH_ALLOCATIONS_TYPE, fetchAllocations);
}
export function* watchUpdateAllocations() {
  yield takeEvery(UPDATE_ALLOCATIONS_TYPE, updateAllocations);
}
export function* watchDistributeAllocationsEvenly() {
  yield takeEvery(DISTRIBUTE_ALLOCATIONS_EVENLY_TYPE, updateAllocationsEvenly);
}
export function* watchRemoveAllocation() {
  yield takeEvery(REMOVE_ALLOCATION_TYPE, removeAllocation);
}

export function* watchRemoveBackup() {
  yield takeEvery(REMOVE_BACKUP_TYPE, removeBackup);
}

export function* watchUpdatePrimaryBeneficiary() {
  yield takeEvery(UPDATE_PRIMARY_BENEFICIARY_TYPE, updatePrimaryBeneficiary);
}

export function* watchUpdatePredecease() {
  yield takeEvery(UPDATE_PREDECEASE_TYPE, updatePredecease);
}

export function* watchupdateInheritance() {
  yield takeEvery(UPDATE_INHERITANCE_TYPE, updateInheritance);
}
export function* watchFetchInheritance() {
  yield takeEvery(FETCH_INHERITANCE_TYPE, fetchInheritance);
}

export function* watchFetchBackup() {
  yield takeEvery(FETCH_BACKUP_TYPE, fetchBackup);
}
export function* watchUpdateBackup() {
  yield takeEvery(UPDATE_BACKUP_TYPE, updateBackup);
}

export function* watchRemoveInheritanceAgeMilestone() {
  yield takeEvery(
    REMOVE_INHERITANCE_AGE_MILESTONE_TYPE,
    removeInheritanceAgeMilestone,
  );
}

export function* watchAddInheritanceAgeMilestone() {
  yield takeEvery(
    ADD_INHERITANCE_AGE_MILESTONE_TYPE,
    addInheritanceAgeMilestone,
  );
}

export function* watchUpdateAllocationCard() {
  yield takeEvery(UPDATE_ALLOCATION_CARD_TYPE, handleUpdateAllocationCard);
}

function* handleUpdateAllocationCard({ payload }) {
  const { allocationCardIndex } = payload;

  const { allocations } = yield select(getFormValues(ALLOCATIONS_FORM_ID));
  const allocationCardData = allocations[allocationCardIndex];
  const { type } = allocationCardData;

  let modalKey = "";
  if (type === PERSON_ALLOCATION) {
    modalKey = MODAL_EDIT_BENEFICIARY_PERSON;
    yield put(
      change(PERSON_ALLOCATION_FORM_ID, ADD_PERSON_BENEFICIARY_FIELD_ID, {
        ...allocationCardData,
      }),
    );
  }
  if (type === CHARITY_ALLOCATION) {
    const { registeredName } = allocationCardData;
    if (registeredName) {
      modalKey = MODAL_EDIT_BENEFICIARY_CUSTOM_CHARITY;
      yield put(
        change(
          CUSTOM_CHARITY_FORM_ID,
          REGISTERED_NAME_FIELD_ID,
          allocationCardData.registeredName,
        ),
      );
      yield put(
        change(
          CUSTOM_CHARITY_FORM_ID,
          BUSINESS_NUMBER_FIELD_ID,
          allocationCardData.businessNumber,
        ),
      );
    } else {
      modalKey = MODAL_EDIT_BENEFICIARY_CHARITY;
      yield put(
        change(
          CHARITY_FORM_ID,
          CHARITY_ID_FIELD_ID,
          allocationCardData.charityId,
        ),
      );
    }
  }

  yield put(displayModal(modalKey));
}

export function* watchAllocationChange() {
  yield takeLatest(reduxFormActionTypes.CHANGE, handleAllocationsFormChange);
}

export function* watchUpdatePieChartData() {
  yield takeEvery(UPDATE_PIE_CHART_DATA_TYPE, handleAllocationsFormChange);
}

export function* watchHandleRemoveBackupOnProgress() {
  yield takeEvery(
    REMOVE_PERSON_BACKUP_ON_PROGRESS_TYPE,
    handleRemovePersonBackupOnProgress,
  );
}

export function* watchBackupsNextPage() {
  yield takeEvery(BACKUPS_NEXT_PAGE_TYPE, handleBackupsNextPage);
}

export function* watchUpdateCharityBackupOnProgress() {
  yield takeEvery(
    UPDATE_CHARITY_BACKUP_ON_PROGRESS_TYPE,
    handleUpdateCharityBackupOnProgress,
  );
}

export function* watchRemoveCharityBackupOnProgress() {
  yield takeEvery(
    REMOVE_CHARITY_BACKUP_ON_PROGRESS_TYPE,
    handleRemoveCharityBackupOnProgress,
  );
}

export function* watchHandleOpenModalCustomCharity() {
  yield takeEvery(OPEN_MODAL_CUSTOM_CHARITY_TYPE, handleOpenModalCustomCharity);
}
